import { ZipCodeArea } from 'api/model';

export const addresses = [
  'Enbackagården',
  'Morvall Färilavägen',
  'Nytorpsvägen',
  'Fornäng',
  'Nils Grises Sträte',
  'Tulpanv',
  'Buanvägen',
  'Torvvägen',
  'Violvägen',
  'Lillesäter',
  'Anders Sadelmakares Gränd',
  'Idvägen',
  'Gulleråsen Västabäcksgatan',
  'Östbygatan',
  'Liljerum Grenadjärtorpet',
  'Villagatan',
  'Södra Kroksdal',
  'Stackekärr',
  'Björkvägen',
  'Figgeberg Gårdeby',
  'Vipgränden',
  'Nästvattnet',
  'Bottna Berghem',
  'Hagagatan',
  'Gamla Svedalavägen',
  'Mellangården',
  'Stora Nygatan',
  'Järnvägsgränd',
  'Bäckaskog Lindsnåret',
  'Linddalen Lisselängen',
  'Grengatan',
  'Vimne Norrgård',
  'Tjustvägen',
  'Granvik Bölet',
  'Vikingavägen',
  'Junigatan',
  'Kållesjö Torwa',
  'Paviljonggatan',
  'Berg Hagaberg',
  'Hagabergsvägen',
  'Logevägen',
  'Albäck',
  'Edåsa Frälsegården',
  'Hylkavägen',
  'Torsgränd 7',
  'Vänortsstråket',
  'Sportfältsvägen',
  'Anga Angebjärsväg',
  'S:T Olofsgatan',
  'S:T JONAS vägen',
  'Östra Aspevägen',
  'Rickards Plan',
  'Lantmannagatan',
  'Granskogsvägen',
  'Strandvägen',
  'Karlbergsvägen',
  'Kullebyvägen',
  'Oskarshamnsvägen',
  'Flengatan',
  'Segeltorsvägen',
  'Sickla strand',
  'Sickla allè',
  'Nysätravägen',
  'Nya gatan',
  'Gamla gatan',
  'Nygamla gatan',
  'Askövägen',
  'Nåttarövägen',
  'Dalagatan',
  'Mosebacke',
  'Storgatan',
  'Lillgatan',
  'Rådjursstigen',
  'Kattvägen',
  'Kattstigen',
  'Katt gatan',
  'Pippi långstrumps gatan',
  'Ankeborgsvägen',
];

export const cities = [
  'Falun',
  'Arvika',
  'Hyllinge',
  'Västervik',
  'Jönköping',
  'Valdemarsvik',
  'Dösjebro',
  'Ljungsbro',
  'Tingsryd',
  'Luleå',
  'Haparanda',
  'Töre',
  'Kalix',
  'Jockmock',
  'Stockholm',
  'Saltsjö-boo',
  'Nacka',
  'Enskede',
  'Södermalm',
  'Hällingsjö',
  'Björbo',
  'Eskillunda',
  'Solna',
  'Sollentuna',
  'Torshammra',
  'Lidingö',
  'Danderyd',
  'Täby',
  'Torsvalla',
  'Katrineholm',
  'Visby',
  'Villinge',
  'Växsjö',
  'Tullinge',
  'Tordvalla',
  'Solvalla',
  'Norrbo',
  'Fjälla',
  'Björkvik',
  'Vingåker',
  'Falstebo',
  'Flen',
  'Fredrika',
  'Nordinge',
  'Klix',
  'Trix',
  'Flocka',
  'By',
  'Bo',
  'Trollebo',
];

export interface IAddress {
  name: string;
  address: string;
  zip: string;
  city: string;
  county?: ZipCodeArea;
}

export const formatAddress = (address: IAddress): string => {
  return `${address.name ? `${address.name}, ` : ''}${
    address.address ? address.address : ''
  }\n${address.zip} ${address.city}${
    address.county?.areaName ? `, ${address.county?.areaName}` : ''
  }`;
};
