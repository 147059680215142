import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { IAddress } from 'utils/address';
import {
  formatTime,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import useCompanies from 'contexts/basicData/useCompanies';
import useZipCodeAreas from 'hooks/useZipCodeAreas';
import toast from 'react-hot-toast';
import { SliderOption } from 'components/inputs/Slider';
import useGetFixedAssignmentTimeFromAddresses from '../CaseAssignments/useGetFixedAssignmentTimeFromAddresses';
import { AssignmentModel, CaseTypeEnum } from 'api/model';

interface IPlannedStopOverData {
  firstAssignmentEstimatedDurationId: number;
  firstAssignmentBookedTo?: Date;
  firstAssignmentEstimatedDuration?: number;
  firstAssignmentEstimatedStartTime?: Date;
  secondAssignmentEstimatedDurationId: number;
  secondAssignmentBookedTo?: Date;
  secondAssignmentEstimatedDuration?: number;
  secondAssignmentEstimatedStartTime?: Date;
  stopoverAddress: IAddress;
}

const getTimeDifferenceBetweenEstimatedStartTimeAndBookedTo = (
  estimatedStartTime?: Date,
  bookedTo?: Date
) => {
  if (!estimatedStartTime || !bookedTo) return 0;
  const estimatedStartTimeInMilliseconds = estimatedStartTime.getTime();
  const bookedToInMilliseconds = bookedTo.getTime();
  return bookedToInMilliseconds - estimatedStartTimeInMilliseconds;
};

// check if the last assignment is the main assignment (depends on case type)
export const isSecondAssignmentMainAssignment = (caseTypeId: CaseTypeEnum) => {
  switch (caseTypeId) {
    case CaseTypeEnum.Purchase:
      return false;

    default:
      return true;
  }
};

const getDefaultValues = (
  assignmentModel: AssignmentModel,
  caseTypeID: CaseTypeEnum
): IPlannedStopOverData => {
  const stopoverAddress: IAddress = {
    name: '',
    address: '',
    zip: '',
    city: '',
    county: undefined,
  };

  const result: IPlannedStopOverData = isSecondAssignmentMainAssignment(
    caseTypeID
  )
    ? {
        // home deliveries use the original assignment's bookedTo and estimatedStartTime for the second assignment (as that's when the FT meets the customer), and resulting data as the second assignment's times
        firstAssignmentEstimatedDurationId: 0,
        firstAssignmentBookedTo: undefined,
        firstAssignmentEstimatedDuration: undefined,
        firstAssignmentEstimatedStartTime: undefined,
        secondAssignmentEstimatedDurationId: 0,
        secondAssignmentBookedTo: assignmentModel.bookedTo,
        secondAssignmentEstimatedDuration: assignmentModel.estimatedDuration,
        secondAssignmentEstimatedStartTime: assignmentModel.estimatedStartTime,
        stopoverAddress,
      }
    : {
        // other case types use the original assignment's bookedTo and estimatedStartTime for the first assignment (as that's when the FT meets the customer), and resulting data as the first assignment's times
        firstAssignmentEstimatedDurationId: 0,
        firstAssignmentBookedTo: assignmentModel.bookedTo,
        firstAssignmentEstimatedDuration: assignmentModel.estimatedDuration,
        firstAssignmentEstimatedStartTime: assignmentModel.estimatedStartTime,
        secondAssignmentEstimatedDurationId: 0,
        secondAssignmentBookedTo: undefined,
        secondAssignmentEstimatedDuration: undefined,
        secondAssignmentEstimatedStartTime: undefined,
        stopoverAddress,
      };

  return result;
};
interface UsePlannedStopOverFormProps {
  originalAssignment: AssignmentModel;
  caseTypeId: CaseTypeEnum;
  onAddressPicked(
    stopoverAddress: IAddress,

    firstAssignmentBookedTo: Date,
    firstAssignmentEstimatedDuration: number,
    firstAssignmentEstimatedStartTime: Date,

    secondAssignmentBookedTo: Date,
    secondAssignmentEstimatedDuration: number,
    secondAssignmentEstimatedStartTime: Date
  ): void;
}

function usePlannedStopOverForm({
  originalAssignment,
  caseTypeId,
  onAddressPicked,
}: UsePlannedStopOverFormProps) {
  const { companies, getCompanyAddressDictKey } = useCompanies();

  const originalAssignmentFormData = useForm<AssignmentModel>({
    defaultValues: originalAssignment,
  });
  const originalAssignmentFormDataValues = originalAssignmentFormData.watch();

  // setup of form to control the stopover
  const stopoverFormData = useForm<IPlannedStopOverData>({
    defaultValues: getDefaultValues(originalAssignment, caseTypeId),
  });

  const stopoverFormValues = stopoverFormData.watch();
  // reset form on mount
  useEffect(() => {
    stopoverFormData.reset(
      getDefaultValues(originalAssignmentFormDataValues, caseTypeId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    originalAssignmentFormDataValues.estimatedDuration,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    originalAssignmentFormDataValues.estimatedStartTime?.getTime(),
  ]);

  const { zipCodeAreaFromZipCode } = useZipCodeAreas();
  const { getFixedAssignmentTimeFromAddressData } =
    useGetFixedAssignmentTimeFromAddresses();

  const { name, address, zip, city, county } =
    stopoverFormValues.stopoverAddress;

  // reset stopoverFormData on cahnged originalAssignmentFormData
  useEffect(() => {
    stopoverFormData.reset(getDefaultValues(originalAssignment, caseTypeId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalAssignment]);

  const fixedAssignmentTimeFromStartToStopover: number | undefined =
    useMemo(() => {
      // find company by address
      const company = companies.find(
        (x) =>
          getCompanyAddressDictKey(x.address, x.zip, x.city) ===
          getCompanyAddressDictKey(
            stopoverFormValues.stopoverAddress.address,
            stopoverFormValues.stopoverAddress.zip,
            stopoverFormValues.stopoverAddress.city
          )
      );

      const toCounty = zipCodeAreaFromZipCode(company?.zip ?? '');

      if (!company || !stopoverFormValues.stopoverAddress.county)
        return undefined;
      const result = getFixedAssignmentTimeFromAddressData({
        fromAddress: {
          name: originalAssignment.fromName,
          address: originalAssignment.fromAddress,
          zip: originalAssignment.fromZip,
          city: originalAssignment.fromCity,
          county: originalAssignment.fromCounty,
        },
        toAddress: {
          name: company.name,
          address: company.address,
          zip: company.zip,
          city: company.city,
          county: toCounty,
        },
      });

      return result?.fixedTimeInMillseconds;
    }, [
      companies,
      zipCodeAreaFromZipCode,
      stopoverFormValues.stopoverAddress.county,
      stopoverFormValues.stopoverAddress.address,
      stopoverFormValues.stopoverAddress.zip,
      stopoverFormValues.stopoverAddress.city,
      getFixedAssignmentTimeFromAddressData,
      originalAssignment.fromName,
      originalAssignment.fromAddress,
      originalAssignment.fromZip,
      originalAssignment.fromCity,
      originalAssignment.fromCounty,
      getCompanyAddressDictKey,
    ]);

  const fixedAssignmentTimeFromStopoverToEnd: number | undefined =
    useMemo(() => {
      // find company by address
      const company = companies.find(
        (x) =>
          getCompanyAddressDictKey(x.address, x.zip, x.city) ===
          getCompanyAddressDictKey(
            stopoverFormValues.stopoverAddress.address,
            stopoverFormValues.stopoverAddress.zip,
            stopoverFormValues.stopoverAddress.city
          )
      );
      const fromCounty = zipCodeAreaFromZipCode(company?.zip ?? '');

      if (!company || !stopoverFormValues.stopoverAddress.county)
        return undefined;

      const result = getFixedAssignmentTimeFromAddressData({
        fromAddress: {
          name: company.name,
          address: company.address,
          zip: company.zip,
          city: company.city,
          county: fromCounty,
        },
        toAddress: {
          name: originalAssignment.toName,
          address: originalAssignment.toAddress,
          zip: originalAssignment.toZip,
          city: originalAssignment.toCity,
          county: originalAssignment.toCounty,
        },
      });

      return result?.fixedTimeInMillseconds;
    }, [
      companies,
      stopoverFormValues.stopoverAddress.address,
      stopoverFormValues.stopoverAddress.city,
      stopoverFormValues.stopoverAddress.county,
      stopoverFormValues.stopoverAddress.zip,
      getCompanyAddressDictKey,
      getFixedAssignmentTimeFromAddressData,
      originalAssignment.toAddress,
      originalAssignment.toCity,
      originalAssignment.toCounty,
      originalAssignment.toName,
      originalAssignment.toZip,
      zipCodeAreaFromZipCode,
    ]);

  const hasFixedAssignmentTime =
    fixedAssignmentTimeFromStartToStopover !== undefined ||
    fixedAssignmentTimeFromStopoverToEnd !== undefined;

  const adjustSecondEstimatedStartTimeToNextDay = () => {
    if (!stopoverFormValues.secondAssignmentEstimatedStartTime) return;
    const newDate = new Date(
      stopoverFormValues.secondAssignmentEstimatedStartTime
    );
    newDate.setDate(newDate.getDate() + 1); // set to next day
    newDate.setHours(7); // set to 7:00
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    stopoverFormData.setValue('secondAssignmentEstimatedStartTime', newDate);
  };

  const adjustFirstEstimatedStartTimeToPreviousDay = () => {
    if (
      !stopoverFormValues.firstAssignmentEstimatedStartTime ||
      !stopoverFormValues.firstAssignmentEstimatedDuration
    )
      return;
    let newDate = new Date(
      stopoverFormValues.firstAssignmentEstimatedStartTime
    );
    newDate.setDate(newDate.getDate() - 1); // set to previous day
    newDate.setHours(19); // set to 19:00
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    // adjust back with estimated duration to have the assignment end before 19:00
    newDate = new Date(
      newDate.getTime() - stopoverFormValues.firstAssignmentEstimatedDuration
    );

    stopoverFormData.setValue('firstAssignmentEstimatedStartTime', newDate);
  };

  /**
   * Returns a number of possible estimated durations in milliseconds, with:
   * - the min value being 0
   * - the max value being the original assignment's estimated duration
   * - in steps / increments of 1 minute
   * - including the original assignment's estimated duration (even if it's not a multiple of 1 minute)
   */
  const estimatedDurationOptions = useMemo((): SliderOption[] => {
    const originalDuration = originalAssignment.estimatedDuration ?? 0;
    const maxDuration = Math.ceil(originalDuration / 60000) * 60000; // Round up to the nearest minute
    const options: SliderOption[] = [];

    for (let i = 0; i <= maxDuration; i += 60000) {
      // Increment by 1 minute
      options.push({
        value: i,
        label: getHoursAndMinutesFromMillisecondsString(i),
      });
    }

    // Add fixed times if any
    if (fixedAssignmentTimeFromStartToStopover) {
      options.push({
        value: fixedAssignmentTimeFromStartToStopover,
        label: getHoursAndMinutesFromMillisecondsString(
          fixedAssignmentTimeFromStartToStopover
        ),
      });
    }
    if (fixedAssignmentTimeFromStopoverToEnd) {
      options.push({
        value: fixedAssignmentTimeFromStopoverToEnd,
        label: getHoursAndMinutesFromMillisecondsString(
          fixedAssignmentTimeFromStopoverToEnd
        ),
      });
    }

    // Sort the options in ascending order
    options.sort((a, b) => a.value - b.value);

    return options;
  }, [
    fixedAssignmentTimeFromStartToStopover,
    fixedAssignmentTimeFromStopoverToEnd,
    originalAssignment.estimatedDuration,
  ]);

  const isOkToCreateStopover =
    !!name &&
    !!address &&
    !!zip &&
    !!city &&
    !!county &&
    !!stopoverFormValues.firstAssignmentBookedTo &&
    !!stopoverFormValues.firstAssignmentEstimatedDuration &&
    !!stopoverFormValues.firstAssignmentEstimatedStartTime &&
    !!stopoverFormValues.secondAssignmentBookedTo &&
    !!stopoverFormValues.secondAssignmentEstimatedDuration &&
    !!stopoverFormValues.secondAssignmentEstimatedStartTime;

  const handleOk = () => {
    if (!isOkToCreateStopover) {
      toast.error('Alla fält måste vara ifyllda');
      return;
    }

    onAddressPicked(
      { name, address, zip, city, county },
      stopoverFormValues.firstAssignmentBookedTo!,
      stopoverFormValues.firstAssignmentEstimatedDuration!,
      stopoverFormValues.firstAssignmentEstimatedStartTime!,
      stopoverFormValues.secondAssignmentBookedTo!,
      stopoverFormValues.secondAssignmentEstimatedDuration!,
      stopoverFormValues.secondAssignmentEstimatedStartTime!
    );
  };

  const handleFirstAssignmentEstimatedDurationChange = (durationId: number) => {
    const { value } = estimatedDurationOptions[durationId];
    const originalDuration = originalAssignment.estimatedDuration ?? 0;
    const remainingDuration = originalDuration - value;
    const remainingDurationIndex = estimatedDurationOptions.findIndex(
      (option) => option.value >= remainingDuration
    );

    stopoverFormData.setValue('firstAssignmentEstimatedDurationId', durationId);
    stopoverFormData.setValue('firstAssignmentEstimatedDuration', value);
    stopoverFormData.setValue(
      'secondAssignmentEstimatedDurationId',
      remainingDurationIndex
    );
    stopoverFormData.setValue(
      'secondAssignmentEstimatedDuration',
      remainingDuration
    );
  };

  const handleSecondAssignmentEstimatedDurationChange = (
    durationId: number
  ) => {
    const { value } = estimatedDurationOptions[durationId];
    const originalDuration = originalAssignment.estimatedDuration;
    if (!originalDuration) return;
    const remainingDuration = originalDuration - value;
    const remainingDurationIndex = estimatedDurationOptions.findIndex(
      (option) => option.value >= remainingDuration
    );
    stopoverFormData.setValue(
      'secondAssignmentEstimatedDurationId',
      durationId
    );
    stopoverFormData.setValue(
      'secondAssignmentEstimatedDuration',
      estimatedDurationOptions[durationId].value
    );
    stopoverFormData.setValue(
      'firstAssignmentEstimatedDurationId',
      remainingDurationIndex
    );
    stopoverFormData.setValue(
      'firstAssignmentEstimatedDuration',
      remainingDuration
    );
  };

  // console.log('usePlannedStopOverForm', {
  //   firstAssignmentEstimatedDurationId,
  //   secondAssignmentEstimatedDurationId,
  // });

  // update estimated durations based on new values
  // useEffect(() => {
  //   const firstAssignmentNewValue =
  //     estimatedDurationOptions[firstAssignmentEstimatedDurationId]?.value;
  //   const secondAssignmentNewValue =
  //     estimatedDurationOptions[secondAssignmentEstimatedDurationId]?.value;
  //   if (firstAssignmentNewValue !== undefined) {
  //     stopoverFormData.setValue(
  //       'firstAssignmentEstimatedDuration',
  //       estimatedDurationOptions[firstAssignmentEstimatedDurationId].value
  //     );
  //   }

  //   if (secondAssignmentNewValue !== undefined) {
  //     stopoverFormData.setValue(
  //       'secondAssignmentEstimatedDuration',
  //       estimatedDurationOptions[secondAssignmentEstimatedDurationId].value
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [firstAssignmentEstimatedDurationId, secondAssignmentEstimatedDurationId]);

  // update estimated start times and booked to based on new durations
  useEffect(() => {
    const originalStartTime = originalAssignment.estimatedStartTime;
    if (!originalStartTime) return;
    if (isSecondAssignmentMainAssignment(caseTypeId)) {
      // the second assignment is the crucial time, so adjust the first assignment's estimated start time based on the new durations
      if (
        stopoverFormValues.secondAssignmentEstimatedDuration !== undefined &&
        stopoverFormValues.firstAssignmentEstimatedDuration !== undefined
      ) {
        const newEstimatedStartTime = new Date(
          originalStartTime.getTime() -
            stopoverFormValues.secondAssignmentEstimatedDuration
        );
        const timeDifferenceBetweenEstimatedStartTimeAndBookedTo =
          getTimeDifferenceBetweenEstimatedStartTimeAndBookedTo(
            newEstimatedStartTime,
            stopoverFormValues.firstAssignmentBookedTo
          );

        stopoverFormData.setValue(
          'firstAssignmentEstimatedStartTime',
          newEstimatedStartTime
        );
        stopoverFormData.setValue(
          'firstAssignmentBookedTo',
          new Date(
            newEstimatedStartTime.getTime() +
              timeDifferenceBetweenEstimatedStartTimeAndBookedTo
          )
        );
      }
    } else {
      // the first assignment is the crucial time, so adjust the second assignment's estimated start time based on the new durations
      if (stopoverFormValues.firstAssignmentEstimatedDuration === undefined)
        return;
      const newEstimatedStartTime = new Date(
        originalStartTime.getTime() +
          stopoverFormValues.firstAssignmentEstimatedDuration
      );

      const timeDifferenceBetweenEstimatedStartTimeAndBookedTo =
        getTimeDifferenceBetweenEstimatedStartTimeAndBookedTo(
          stopoverFormValues.firstAssignmentEstimatedStartTime,
          stopoverFormValues.firstAssignmentBookedTo
        );

      stopoverFormData.setValue(
        'secondAssignmentEstimatedStartTime',
        newEstimatedStartTime
      );

      stopoverFormData.setValue(
        'secondAssignmentBookedTo',
        new Date(
          newEstimatedStartTime.getTime() +
            timeDifferenceBetweenEstimatedStartTimeAndBookedTo
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stopoverFormValues.firstAssignmentEstimatedDuration,
    stopoverFormValues.secondAssignmentEstimatedDuration,
  ]);

  // on mount, set the estimated duration of the new assignment to half of the original assignment's estimated duration
  // useEffect(() => {
  //   const originalDuration = originalAssignment.estimatedDuration ?? 0;
  //   const halfDuration = Math.ceil(originalDuration / 2);
  //   const halfDurationIndex = estimatedDurationOptions.findIndex(
  //     (option) => option.value >= halfDuration
  //   );
  //   handleCurrentAssignmentEstimatedDurationChange(halfDurationIndex);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (!fixedAssignmentTimeFromStartToStopover) return;
    const fixedTimeIndex = estimatedDurationOptions.findIndex(
      (option) => option.value === fixedAssignmentTimeFromStartToStopover
    );

    if (fixedTimeIndex >= 0) {
      handleFirstAssignmentEstimatedDurationChange(fixedTimeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedAssignmentTimeFromStartToStopover]);

  useEffect(() => {
    if (!fixedAssignmentTimeFromStopoverToEnd) return;
    const fixedTimeIndex = estimatedDurationOptions.findIndex(
      (option) => option.value === fixedAssignmentTimeFromStopoverToEnd
    );
    if (fixedTimeIndex >= 0) {
      handleSecondAssignmentEstimatedDurationChange(fixedTimeIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedAssignmentTimeFromStopoverToEnd]);

  const disableCreation =
    // the address cannot be the same as the original assignment's to address
    (name === originalAssignment.toName &&
      address === originalAssignment.toAddress &&
      zip === originalAssignment.toZip &&
      city === originalAssignment.toCity &&
      county?.id === originalAssignment.toCounty?.id) ||
    // the address details need to be filled in
    !name ||
    !address ||
    !zip ||
    !city ||
    !county ||
    // the estimated start time of the new assignment needs to be filled in
    !stopoverFormValues.firstAssignmentEstimatedDuration ||
    // the estimated start time of the new assignment needs to be filled in
    !stopoverFormValues.secondAssignmentEstimatedDuration;

  const originalStartTime = originalAssignment.estimatedStartTime;
  const originalEndTime =
    originalAssignment.estimatedStartTime &&
    originalAssignment.estimatedDuration
      ? new Date(
          originalAssignment.estimatedStartTime.getTime() +
            originalAssignment.estimatedDuration
        )
      : undefined;

  const updatedFirstAssignmentEndTimeBasedOnNewDurations = useMemo(() => {
    if (
      !stopoverFormValues.firstAssignmentEstimatedStartTime ||
      !stopoverFormValues.firstAssignmentEstimatedDuration
    )
      return undefined;
    const result = new Date(
      stopoverFormValues.firstAssignmentEstimatedStartTime.getTime() +
        stopoverFormValues.firstAssignmentEstimatedDuration
    );
    return result;
  }, [
    stopoverFormValues.firstAssignmentEstimatedStartTime,
    stopoverFormValues.firstAssignmentEstimatedDuration,
  ]);

  const updatedSecondAssignmentEndTimeBasedOnNewDurations = useMemo(() => {
    if (
      !stopoverFormValues.secondAssignmentEstimatedStartTime ||
      !stopoverFormValues.secondAssignmentEstimatedDuration
    )
      return undefined;
    const result = new Date(
      stopoverFormValues.secondAssignmentEstimatedStartTime.getTime() +
        stopoverFormValues.secondAssignmentEstimatedDuration
    );
    return result;
  }, [
    stopoverFormValues.secondAssignmentEstimatedDuration,
    stopoverFormValues.secondAssignmentEstimatedStartTime,
  ]);

  const endsTooLateOrTooEarly =
    !!address &&
    !!city &&
    !!zip &&
    !!county &&
    updatedSecondAssignmentEndTimeBasedOnNewDurations &&
    (updatedSecondAssignmentEndTimeBasedOnNewDurations.getHours() > 19 ||
      updatedSecondAssignmentEndTimeBasedOnNewDurations.getHours() < 7);

  const startsTooLateOrTooEarly =
    !!address &&
    !!city &&
    !!zip &&
    !!county &&
    stopoverFormValues.firstAssignmentEstimatedStartTime &&
    (stopoverFormValues.firstAssignmentEstimatedStartTime.getHours() > 19 ||
      stopoverFormValues.firstAssignmentEstimatedStartTime.getHours() < 7);

  const handleFirstAssignmentEstimatedStartChange = (newDate: Date) => {
    const resultingEndTime =
      stopoverFormValues.firstAssignmentEstimatedDuration &&
      new Date(
        newDate.getTime() + stopoverFormValues.firstAssignmentEstimatedDuration
      );

    // if the last assignment is the main assignment, prevent the first assignment's resulting end time to be later than the second assignment's estimated start time
    if (isSecondAssignmentMainAssignment(caseTypeId)) {
      if (
        resultingEndTime &&
        stopoverFormValues.secondAssignmentEstimatedStartTime &&
        resultingEndTime.getTime() >
          stopoverFormValues.secondAssignmentEstimatedStartTime?.getTime()
      ) {
        toast.error(
          `Den beräknade sluttiden (${formatTime(resultingEndTime)}) kan inte vara senare än starttiden för uppdrag 2 (${formatTime(stopoverFormValues.secondAssignmentEstimatedStartTime)})`
        );
        return;
      }
    }

    // set the new estimated start time for the second assignment (and booked to if necessary)
    const timeDifferenceBetweenEstimatedStartTimeAndBookedTo =
      getTimeDifferenceBetweenEstimatedStartTimeAndBookedTo(
        stopoverFormValues.firstAssignmentEstimatedStartTime,
        stopoverFormValues.firstAssignmentBookedTo
      );
    stopoverFormData.setValue('firstAssignmentEstimatedStartTime', newDate);

    stopoverFormData.setValue(
      'firstAssignmentBookedTo',
      new Date(
        newDate.getTime() + timeDifferenceBetweenEstimatedStartTimeAndBookedTo
      )
    );
  };

  const handleSecondAssignmentEstimatedStartChange = (newDate: Date) => {
    if (
      updatedFirstAssignmentEndTimeBasedOnNewDurations &&
      newDate.getTime() <
        updatedFirstAssignmentEndTimeBasedOnNewDurations.getTime()
    ) {
      toast.error(
        `Starttiden för uppdrag 2 (${formatTime(newDate)}) kan inte vara tidigare än den beräknade sluttiden för uppdrag 1 (${formatTime(
          updatedFirstAssignmentEndTimeBasedOnNewDurations
        )})`
      );
      return;
    }

    // set the new estimated start time for the second assignment (and booked to if necessary)
    const timeDifferenceBetweenEstimatedStartTimeAndBookedTo =
      getTimeDifferenceBetweenEstimatedStartTimeAndBookedTo(
        stopoverFormValues.secondAssignmentEstimatedStartTime,
        stopoverFormValues.secondAssignmentBookedTo
      );
    stopoverFormData.setValue('secondAssignmentEstimatedStartTime', newDate);

    stopoverFormData.setValue(
      'secondAssignmentBookedTo',
      new Date(
        newDate.getTime() + timeDifferenceBetweenEstimatedStartTimeAndBookedTo
      )
    );
  };

  const handleOriginalAssignmentEstimatedStartTimeChange = (newDate: Date) => {
    originalAssignmentFormData.setValue('estimatedStartTime', newDate);
  };

  const handleOriginalAssignmentEstimatedDurationChange = (
    durationMillis: number
  ) => {
    originalAssignmentFormData.setValue('estimatedDuration', durationMillis);
  };

  return {
    stopOverAddress: { name, address, zip, city, county },
    setStopOverAddressValue: (key: keyof IAddress, value: any) => {
      stopoverFormData.setValue(`stopoverAddress.${key}`, value);
    },
    estimatedDurationOptions,
    originalAssignmentFormData,
    stopoverFormData,
    startsTooLateOrTooEarly,
    endsTooLateOrTooEarly,
    disableCreation,
    originalStartTime,
    originalEndTime,
    updatedFirstAssignmentEndTimeBasedOnNewDurations,
    updatedSecondAssignmentEndTimeBasedOnNewDurations,
    fixedAssignmentTimeFromStartToStopover,
    fixedAssignmentTimeFromStopoverToEnd,
    hasFixedAssignmentTime,

    handleOriginalAssignmentEstimatedStartTimeChange,
    handleOriginalAssignmentEstimatedDurationChange,

    handleFirstAssignmentEstimatedStartChange,
    handleFirstAssignmentEstimatedDurationChange,
    handleSecondAssignmentEstimatedDurationChange,
    handleSecondAssignmentEstimatedStartChange,
    adjustFirstEstimatedStartTimeToPreviousDay,
    adjustSecondEstimatedStartTimeToNextDay,
    handleOk,
  };
}

export default usePlannedStopOverForm;
