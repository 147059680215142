import { useQueryClient } from '@tanstack/react-query';
import { SupplierModel } from 'api/model';
import {
  getSupplierGetSuppliersQueryKey,
  useSupplierAddSupplier,
  useSupplierUpdateSupplier,
} from 'api/supplier/supplier';
import Checkbox from 'components/inputs/Checkbox';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import TextArea from 'components/inputs/TextArea';
import Modal from 'components/Modal';
import Roles from 'constants/Roles';
import useMe from 'contexts/authentication/useMe';
import useModalStack from 'contexts/modal/useModalStack';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface Props {
  supplier?: SupplierModel;
}

const SupplierFormModal: FC<Props> = ({ supplier }) => {
  const isEdit = !!supplier;
  const modalStack = useModalStack();
  const queryClient = useQueryClient();
  const addSupplierCall = useSupplierAddSupplier({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(getSupplierGetSuppliersQueryKey());
      },
    },
  });
  const editSupplierCall = useSupplierUpdateSupplier({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(getSupplierGetSuppliersQueryKey());
        modalStack.pop();
      },
    },
  });
  const me = useMe();

  const { handleSubmit, register } = useForm<SupplierModel>({
    defaultValues: {
      name: supplier?.name,
      description: supplier?.description,
      isActive: supplier?.isActive,
    },
  });

  const onSubmit = handleSubmit((inputData) => {
    if (isEdit) {
      editSupplierCall.mutate({
        data: {
          ...inputData,
          id: supplier?.id,
        },
      });
    } else {
      addSupplierCall.mutate({
        data: inputData,
      });
    }
    modalStack.pop();
  });

  const shouldBeDisabled = useMemo(() => {
    const isDefaultSupplier = supplier?.name === 'Riddermark Bil';
    return isDefaultSupplier || !me?.roles.includes(Roles.SuperAdmin);
  }, [me?.roles, supplier?.name]);

  return (
    <Modal
      title="Leverantör"
      onClose={() => {
        modalStack.pop();
      }}
      buttons={[
        {
          label: isEdit ? 'Spara' : 'Lägg till',
          disabled: shouldBeDisabled,
          loading: addSupplierCall.isLoading || editSupplierCall.isLoading,
          onClick: () => {
            onSubmit();
          },
        },
        {
          label: 'Avbryt',
          onClick: () => {
            modalStack.pop();
          },
        },
      ]}
    >
      <ContentWrapper>
        <LabelWrap label="Namn">
          <Input disabled={shouldBeDisabled} {...register('name')} />
        </LabelWrap>

        <LabelWrap label="Beskrivning">
          <TextArea disabled={shouldBeDisabled} {...register('description')} />
        </LabelWrap>

        <LabelWrap label="Aktiv">
          <Checkbox
            disabled={shouldBeDisabled}
            type="checkbox"
            {...register('isActive')}
          />
        </LabelWrap>
      </ContentWrapper>
    </Modal>
  );
};

export default SupplierFormModal;
