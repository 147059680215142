/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { SupplierModel, SupplierModelBody } from '.././model';
import useSupplierGetSuppliersMutator from '.././mutator/custom-instance';
import useSupplierAddSupplierMutator from '.././mutator/custom-instance';
import useSupplierUpdateSupplierMutator from '.././mutator/custom-instance';

export const useSupplierGetSuppliersHook = () => {
  const supplierGetSuppliers =
    useSupplierGetSuppliersMutator<SupplierModel[]>();

  return (signal?: AbortSignal) => {
    return supplierGetSuppliers({
      url: `/api/Supplier/GetSuppliers`,
      method: 'GET',
      signal,
    });
  };
};

export const getSupplierGetSuppliersQueryKey = () => {
  return [`/api/Supplier/GetSuppliers`] as const;
};

export const useSupplierGetSuppliersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useSupplierGetSuppliersHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSupplierGetSuppliersHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSupplierGetSuppliersQueryKey();

  const supplierGetSuppliers = useSupplierGetSuppliersHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSupplierGetSuppliersHook>>>
  > = ({ signal }) => supplierGetSuppliers(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSupplierGetSuppliersHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SupplierGetSuppliersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSupplierGetSuppliersHook>>>
>;
export type SupplierGetSuppliersQueryError = unknown;

export const useSupplierGetSuppliers = <
  TData = Awaited<ReturnType<ReturnType<typeof useSupplierGetSuppliersHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSupplierGetSuppliersHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSupplierGetSuppliersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useSupplierAddSupplierHook = () => {
  const supplierAddSupplier = useSupplierAddSupplierMutator<void>();

  return (supplierModelBody: SupplierModelBody) => {
    return supplierAddSupplier({
      url: `/api/Supplier/AddSupplier`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: supplierModelBody,
    });
  };
};

export const useSupplierAddSupplierMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSupplierAddSupplierHook>>>,
    TError,
    { data: SupplierModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSupplierAddSupplierHook>>>,
  TError,
  { data: SupplierModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const supplierAddSupplier = useSupplierAddSupplierHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSupplierAddSupplierHook>>>,
    { data: SupplierModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return supplierAddSupplier(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SupplierAddSupplierMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSupplierAddSupplierHook>>>
>;
export type SupplierAddSupplierMutationBody = SupplierModelBody;
export type SupplierAddSupplierMutationError = unknown;

export const useSupplierAddSupplier = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSupplierAddSupplierHook>>>,
    TError,
    { data: SupplierModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useSupplierAddSupplierHook>>>,
  TError,
  { data: SupplierModelBody },
  TContext
> => {
  const mutationOptions = useSupplierAddSupplierMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useSupplierUpdateSupplierHook = () => {
  const supplierUpdateSupplier = useSupplierUpdateSupplierMutator<void>();

  return (supplierModelBody: SupplierModelBody) => {
    return supplierUpdateSupplier({
      url: `/api/Supplier/UpdateSupplier`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: supplierModelBody,
    });
  };
};

export const useSupplierUpdateSupplierMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSupplierUpdateSupplierHook>>>,
    TError,
    { data: SupplierModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSupplierUpdateSupplierHook>>>,
  TError,
  { data: SupplierModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const supplierUpdateSupplier = useSupplierUpdateSupplierHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSupplierUpdateSupplierHook>>>,
    { data: SupplierModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return supplierUpdateSupplier(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SupplierUpdateSupplierMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSupplierUpdateSupplierHook>>>
>;
export type SupplierUpdateSupplierMutationBody = SupplierModelBody;
export type SupplierUpdateSupplierMutationError = unknown;

export const useSupplierUpdateSupplier = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSupplierUpdateSupplierHook>>>,
    TError,
    { data: SupplierModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useSupplierUpdateSupplierHook>>>,
  TError,
  { data: SupplierModelBody },
  TContext
> => {
  const mutationOptions = useSupplierUpdateSupplierMutationOptions(options);

  return useMutation(mutationOptions);
};
