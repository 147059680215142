import { faBan, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SupplierModel } from 'api/model';
import { useSupplierGetSuppliers } from 'api/supplier/supplier';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import Checkbox from 'components/inputs/Checkbox';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import Roles from 'constants/Roles';
import useModalStack from 'contexts/modal/useModalStack';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import SupplierFormModal from './SupplierFormModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActiveFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SupplierSettings = () => {
  const [activeFilter, setActiveFilter] = useState<boolean>(true);
  const modalStack = useModalStack();
  const supplierCall = useSupplierGetSuppliers();

  const columnSettings = useMemo((): ColumnSetting<SupplierModel, object>[] => {
    return [
      {
        head: 'Leverantör',
        width: 150,
        csvValue: (row) => row.name,
        render: (row) => <span>{row.name}</span>,
        sortFunction: (a, b) => a.name.localeCompare(b.name),
      },
      {
        head: 'Beskrivning',
        width: 200,
        csvValue: (row) => row.description ?? '',
        render: (row) => <span>{row.description}</span>,
        sortFunction: (a, b) =>
          (a.description ?? '').localeCompare(b.description ?? ''),
      },
      {
        head: 'Aktiv',
        width: 50,
        csvValue: (row) => (row.isActive ? 'Ja' : 'Nej'),
        render: (row) => (
          <span>
            {' '}
            {row.isActive ? (
              <span>
                <FontAwesomeIcon icon={faCheck} color="green" />
              </span>
            ) : (
              <span>
                <FontAwesomeIcon icon={faBan} color="gray" />
              </span>
            )}
          </span>
        ),
        sortFunction: (a, b) => (a.isActive ? 1 : 0) - (b.isActive ? 1 : 0),
      },
    ];
  }, []);

  const filteredData = useMemo(() => {
    return supplierCall.data?.filter((row) =>
      activeFilter ? row.isActive : true
    );
  }, [activeFilter, supplierCall.data]);

  return (
    <Wrapper>
      <h1>
        Leverantörer{' '}
        {supplierCall.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
      </h1>
      <Header>
        <AuthorizedButton
          roles={[Roles.SuperAdmin]}
          style={{ width: '100px' }}
          onClick={() => modalStack.push(<SupplierFormModal />)}
        >
          Lägg till
        </AuthorizedButton>
        <ActiveFilter>
          <Checkbox
            checked={activeFilter}
            onChange={(e) => setActiveFilter(e.currentTarget.checked)}
          />
          Dölj inaktiva
        </ActiveFilter>
      </Header>

      <Table
        fluid
        columnSettings={columnSettings}
        rows={filteredData ?? []}
        onRowClick={(row) => {
          modalStack.push(<SupplierFormModal supplier={row} />);
        }}
      />
    </Wrapper>
  );
};

export default SupplierSettings;
