/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AssignmentModel,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  AverageAssignmentDurationsClient,
  CaseTypeEnum,
  CompanyModel,
  EstimatedAssignmentDurationDto,
  EventModel,
  ExpenseType,
  StandardizedCommentDto,
} from 'api';
import Input from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import TextButton from 'components/inputs/TextButton';
import KeyValueList, { KeyListRow } from 'components/KeyValueList';
import useTranslations from 'contexts/basicData/useTranslations';
import {
  AssignmentField,
  assignmentFormToAssignment,
  getExceptedExpensesBasedOnSelfTransportation as getExpectedExpensesBasedOnSelfTransportation,
  getUnExceptedExpensesBasedOnSelfTransportation,
  hourInMilliseconds,
  isSelfTransportationAssignment,
  NewAssignmentOverrides,
} from 'components/DetailedCase/DetailedCaseFormProvider/useCaseAssignmentForms';
import {
  FC,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { BasicFormState, ValidationResults } from 'hooks/useForm';
import FormValidations from '../FormValidations';
import Checkbox from 'components/inputs/Checkbox';
import { arraySpreadIf } from 'utils/spreading';
import {
  caseTypeShowsCustomerUpdateMessagesToggle,
  getSelectableCancelTypeOptions,
  getSelectableStatusOptions,
  statusOptionIsDisabled,
} from './utils';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import AddressInput from 'components/AddressInput';
import RemovableInput from 'components/inputs/RemovableInput';
import useGetFixedAssignmentTimeFromAddresses from './useGetFixedAssignmentTimeFromAddresses';
import { autoFormatNumberInputOnBlur } from 'utils/input';
import { useApiCall } from 'swaggerhooks/lib';
import UserModalLink from 'components/UserModalLink';
import {
  formatDateTime,
  getHoursAndMinutesFromMillisecondsString,
  removeMilliseconds,
  toInputDateTimeString,
} from 'utils/date-helpers';
import useUsers from 'contexts/basicData/useUsers';
import esitimatedAssignmentDurations from 'utils/esitimatedAssignmentDurations';
import MultiSelect, { Option } from 'components/inputs/MultiSelect';
import PrioritizedCaseAssignmentUserPicker from 'components/PrioritizedCaseAssignmentUserPicker';
import { useDetailedCaseDataForm } from '../DetailedCaseFormProvider';
import { caseDataFormToCaseModel } from '../DetailedCaseFormProvider/useCaseDataForm';
import StandardizedComments from 'components/StandardizedComments/StandardizedComments';
import {
  expectableExpenses,
  hardCodedEstimatedDurations,
  maxStandByTimeInHours,
  userNamesExcemptFromEstimationValidation,
} from 'constants/AppConstants';
import useResultingStartAndEndTime from './useResultingStartAndEndTimes';
import useStandardizedComments from 'contexts/standardizedComments/useStandardizedComments';
import { getUpdatedExpectedExpensesList } from 'utils/expected-expenses';
import EstimatedDurationPicker from './EstimatedDurationPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faExternalLink,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useUserGetDrivingPolicyReportForUnPlannedAssignment } from 'api/user/user';
import {
  AssigneeWarning,
  AssignmentCancelTypeEnum,
  AssignmentDeviationEnum,
  CompanyTransportPriceModel,
  ExpectedExpenseDto,
} from 'api/model';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import TooltipInfoWarning from 'components/TooltipInfoWarning';
import useAssignmentWarningsAndMultiplePurchases from 'hooks/useAssignmentWarningsAndMultiplePurchases';
import WorkHoursSpan from './WorkHoursSpan';
import { useAssignmentGetBookedAssignmentsForUserAndDate } from 'api/assignment/assignment';
import { makeDateComparator } from 'utils/sorting';
import { millisecondsInHour } from 'date-fns';
import featureToggleUtil from 'utils/feature-toggle-util';

const Wrapper = styled.div<{ isNew?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  padding-left: 10px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
  background: ${({ theme, isNew }) =>
    isNew ? theme.colors.background.highlight : 'transparent'};
`;

const PaddedSpan = styled.span`
  padding: 0 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const StyledKVList = styled(KeyValueList)`
  flex: 1;
`;

const ExtraInfoInput = styled(AutoSizedTextArea)`
  resize: vertical;
  width: 395px;
  max-width: 395px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 10px;
  padding-right: 5px;

  button {
    font-weight: bold;
  }
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
`;

const allAssignmentTypeOptions: AssignmentTypeEnum[] = Object.values(
  AssignmentTypeEnum
).filter((s) => typeof s === 'number') as number[];

interface Props {
  assignmentId: number;
  cancelable?: boolean;
  state: BasicFormState<AssignmentField>;
  validation?: ValidationResults<AssignmentField>;
  currentCaseType: CaseTypeEnum;
  caseEvents: EventModel[];
  unmodifiedAssignment?: AssignmentModel;
  newAssignmentOverrides?: NewAssignmentOverrides;
  onChange(assignmentId: number, field: AssignmentField, value: string): void;
  onCancel(assignmentId: number): void;
}

const CaseAssignmentForm: FC<Props> = ({
  assignmentId,
  cancelable,
  state,
  validation,
  currentCaseType,
  caseEvents,
  unmodifiedAssignment,
  newAssignmentOverrides,
  onChange,
  onCancel,
}) => {
  const loggedInUser = useMe();
  const { state: caseState } = useDetailedCaseDataForm();
  const globalStandardizedComments = useStandardizedComments();

  const assignmentTypeOptions: AssignmentTypeEnum[] = useMemo(
    () =>
      allAssignmentTypeOptions.filter((v) => {
        switch (v) {
          case AssignmentTypeEnum.PlannedFurtherTransport:
            return featureToggleUtil.allowSeeingPlannedStopover(loggedInUser);

          case AssignmentTypeEnum.CarPlaceholder:
          case AssignmentTypeEnum.GoByBoat:
          case AssignmentTypeEnum.GoByTrain:
          case AssignmentTypeEnum.GoByPlane:
          case AssignmentTypeEnum.GoByBus:
          case AssignmentTypeEnum.GoByTaxi:
            return currentCaseType === CaseTypeEnum.Placeholder; // only show these for placeholder cases
          default:
            return currentCaseType !== CaseTypeEnum.Placeholder; // only show the rest for non-placeholder cases
        }
      }),
    [currentCaseType, loggedInUser]
  );

  // handle if we get overrides for the new assignment (e.g. from a placeholder creation)
  useEffect(() => {
    if (newAssignmentOverrides && assignmentId === -1) {
      if (newAssignmentOverrides?.firstPossibleStartTime !== undefined) {
        // set the estimated start time to the first possible start time
        onChange(
          assignmentId,
          AssignmentField.estimatedStartTime,
          toInputDateTimeString(newAssignmentOverrides.firstPossibleStartTime)
        );
        // set the first possible start time to the first possible start time to enable validations
        onChange(
          assignmentId,
          AssignmentField.firstPossibleStartTime,
          toInputDateTimeString(newAssignmentOverrides.firstPossibleStartTime)
        );
      }
      if (newAssignmentOverrides?.lastPossibleEndTime !== undefined) {
        // set the last possible end time to the last possible end time to enable validations
        onChange(
          assignmentId,
          AssignmentField.lastPossibleEndTime,
          toInputDateTimeString(newAssignmentOverrides.lastPossibleEndTime)
        );
      }
      if (currentCaseType === CaseTypeEnum.Placeholder) {
        // set the initial assignment type to car placeholder
        onChange(
          assignmentId,
          AssignmentField.assignmentTypeID,
          AssignmentTypeEnum.CarPlaceholder.toString()
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId, newAssignmentOverrides]);

  const {
    assignmentStatuses,
    assignmentTypes,
    cancelTypes,
    zipCodeAreas,
    expenseTypes,
  } = useTranslations();
  const avgDurationCallTimeoutRef = useRef<NodeJS.Timeout>();

  const [suggestedEstimatedDuration, setSuggestedEstimatedDuration] = useState<
    { min: number; max: number; average: number } | undefined
  >();
  const [estimatedDurationData, setEstimatedDurationData] =
    useState<EstimatedAssignmentDurationDto>();
  const usersById = useUsers();
  const { getFixedAssignmentTimeFromAddresses } =
    useGetFixedAssignmentTimeFromAddresses();

  const getBookedAssignmentsForUserAndDate =
    useAssignmentGetBookedAssignmentsForUserAndDate();

  const getAverageAssignmentDurationCall = useApiCall(
    AverageAssignmentDurationsClient,
    (
      c,
      caseType: CaseTypeEnum,
      assignmentType: AssignmentTypeEnum,
      fromCounty: string,
      toCounty: string,
      fromZip: string,
      toZip: string
    ) =>
      c.getAverageAssignmentDuration(
        caseType,
        assignmentType,
        fromCounty,
        toCounty,
        fromZip,
        toZip
      )
  );

  const expenseTypeOptions: Option<string>[] = useMemo(
    () =>
      expectableExpenses
        .filter((s) => typeof s === 'number')
        .sort((a, b) => {
          const aLabel = expenseTypes[a as ExpenseType];
          const bLabel = expenseTypes[b as ExpenseType];
          if (aLabel && bLabel) {
            return aLabel.localeCompare(bLabel);
          }
          return 0; // handle undefined and null cases, or adapt as needed
        })
        .map((v) => ({
          key: v.toString(),
          value: v.toString(),
          label: expenseTypes[v as ExpenseType] ?? '',
        })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const currentAssignment = useMemo(
    () =>
      assignmentFormToAssignment(
        unmodifiedAssignment,
        state,
        usersById,
        zipCodeAreas
      ),
    [unmodifiedAssignment, state, usersById, zipCodeAreas]
  );

  const {
    multiplePurchasesList,
    thereAreMultiplePurchasesForAssignedUser,
    isViolatingDrivingPolicy,
  } = useAssignmentWarningsAndMultiplePurchases(
    currentCaseType,
    currentAssignment,
    true
  );

  useEffect(() => {
    if (
      currentCaseType === CaseTypeEnum.Placeholder &&
      !currentAssignment?.assigneeShouldBookTheirOwnTicket
    ) {
      // make sure that there is an expected expense based on the type of transportation
      const expectedExpense = getExpectedExpensesBasedOnSelfTransportation(
        currentAssignment.assignmentTypeID
      );
      const notExpectedExpenses =
        getUnExceptedExpensesBasedOnSelfTransportation(
          currentAssignment.assignmentTypeID
        );

      if (expectedExpense === undefined) {
        return;
      }
      const existingExpectedExpense = currentAssignment.expectedExpenses.find(
        (e) => e.type === expectedExpense
      );

      if (!existingExpectedExpense) {
        const updatedExpectedExpenses = [
          ...(currentAssignment.expectedExpenses.map((e) => e.type) ?? []),
          expectedExpense,
        ];
        // remove any not expected expenses
        const updatedExpectedExpensesList = updatedExpectedExpenses.filter(
          (e) => !notExpectedExpenses.includes(e)
        );

        onChange(
          assignmentId,
          AssignmentField.expectedExpenses,
          Array.from(updatedExpectedExpensesList).join(',')
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCaseType,
    currentAssignment?.assigneeShouldBookTheirOwnTicket,
    currentAssignment.assignmentTypeID,
    currentAssignment.expectedExpenses,
    assignmentId,
  ]);

  useEffect(() => {
    // highlight if any of the other purchases has estimated duration and estimated starttime set in the form
    onChange(
      assignmentId,
      AssignmentField.hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime,
      thereAreMultiplePurchasesForAssignedUser(
        Number(state[AssignmentField.assignedTo])
      )
        ? 'true'
        : 'false'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplePurchasesList]);

  const derivedDuration = useMemo(() => {
    if (currentAssignment.startTime && currentAssignment.endTime) {
      return (
        new Date(currentAssignment.endTime).getTime() -
        new Date(currentAssignment.startTime).getTime()
      );
    }

    return currentAssignment.estimatedDuration ?? 0;
  }, [currentAssignment]);

  const drivingPolicyReportResult =
    useUserGetDrivingPolicyReportForUnPlannedAssignment(
      {
        userId: currentAssignment.assignedTo?.userID,
        bookedTo: currentAssignment.bookedTo,
        startOrEstimatedStartTime:
          currentAssignment.startTime ?? currentAssignment.estimatedStartTime,
        estimatedDuration: derivedDuration,
        endOrEstimatedEndTime: currentAssignment.endTime,
        excludeAssignmentId: currentAssignment.assignmentID,
      },
      {
        query: {
          enabled:
            !!currentAssignment.assignedTo &&
            !userNamesExcemptFromEstimationValidation.includes(
              currentAssignment.assignedTo.name
            ),
        },
      }
    );

  useEffect(
    () => {
      const fetchAverageDuration = async () => {
        // if (state)
        const { assignmentTypeID, fromCounty, toCounty, fromZip, toZip } =
          assignmentFormToAssignment(
            unmodifiedAssignment,
            state,
            usersById,
            zipCodeAreas
          );
        if (
          !fromCounty?.areaName ||
          !toCounty?.areaName ||
          !fromZip ||
          !toZip
        ) {
          return; // we need them for the average duration
        }

        // set estimated duration to hard-coded value if it's not already set

        if (
          state[AssignmentField.estimatedDuration] === '0' ||
          state[AssignmentField.estimatedDuration] === ''
        ) {
          const key =
            `${fromCounty.areaName}-${toCounty.areaName}`.toLowerCase();
          const hardcodedValue =
            hardCodedEstimatedDurations[currentCaseType]?.[key];
          if (hardcodedValue) {
            onChange(
              assignmentId,
              AssignmentField.estimatedDuration,
              hardcodedValue.toString()
            );
          }
        }
        // get the number of minutes from the average duration
        const [result] = await getAverageAssignmentDurationCall.run(
          currentCaseType,
          assignmentTypeID,
          fromCounty?.areaName,
          toCounty?.areaName,
          fromZip,
          toZip
        );

        if (result !== null && result.sampleSize > 0) {
          // convert the number of seconds to milliseconds in order to use the estimatedDurationOptions array
          const millisAverage = result.average * 1000 * 60;
          const millisMin = result.min * 1000 * 60;
          const millisMax = result.max * 1000 * 60;

          // return the nearest value in the estimatedDurationOptions array
          const nearestAverage =
            esitimatedAssignmentDurations.getNearestEstimatedDuration(
              millisAverage
            );
          const nearestMin =
            esitimatedAssignmentDurations.getNearestEstimatedDuration(
              millisMin
            );
          const nearestMax =
            esitimatedAssignmentDurations.getNearestEstimatedDuration(
              millisMax
            );

          // note down our system estimates
          if (
            state[AssignmentField.systemEstimatedDurationExact] !==
            millisAverage.toString()
          ) {
            onChange(
              assignmentId,
              AssignmentField.systemEstimatedDurationExact,
              millisAverage.toString()
            );
          }
          if (
            state[AssignmentField.systemEstimatedDurationApprox] !==
            nearestAverage.toString()
          ) {
            onChange(
              assignmentId,
              AssignmentField.systemEstimatedDurationApprox,
              nearestAverage.toString()
            );
          }

          setSuggestedEstimatedDuration({
            min: nearestMin,
            max: nearestMax,
            average: nearestAverage,
          });
        } else {
          setSuggestedEstimatedDuration(undefined);
        }
        setEstimatedDurationData(result ?? undefined);
      };
      if (avgDurationCallTimeoutRef.current) {
        clearTimeout(avgDurationCallTimeoutRef.current);
      }
      avgDurationCallTimeoutRef.current = setTimeout(fetchAverageDuration, 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentCaseType,
      zipCodeAreas,
      usersById,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      state[AssignmentField.assignedTo],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      state[AssignmentField.fromZip],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      state[AssignmentField.toZip],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      state[AssignmentField.assignmentTypeID],
    ]
  );

  // set estimated duration to fixedAssignmentTime if it is set
  useEffect(() => {
    if (
      state[AssignmentField.fixedAssignmentTimeInHours] !== '' &&
      state[AssignmentField.fixedAssignmentTimeInHours] !== '0'
    ) {
      const fixedAssignmentTimeInMilliseconds = Math.floor(
        Number(state[AssignmentField.fixedAssignmentTimeInHours]) *
          hourInMilliseconds
      );

      onChange(
        assignmentId,
        AssignmentField.estimatedDuration,
        fixedAssignmentTimeInMilliseconds.toString()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId, state[AssignmentField.fixedAssignmentTimeInHours]]);

  const clearEstimatedDuration = () => {
    onChange(assignmentId, AssignmentField.estimatedDuration, '0');
    onChange(assignmentId, AssignmentField.systemEstimatedDurationExact, '0');
    onChange(assignmentId, AssignmentField.systemEstimatedDurationApprox, '0');
  };

  const {
    resultingStartTime,
    resultingEndTime,
    calculateResultingStartAndEndTimeWithoutEstimatedStartTime,
  } = useResultingStartAndEndTime({
    caseType: currentCaseType,
    assignmentType: Number(
      state[AssignmentField.assignmentTypeID]
    ) as AssignmentTypeEnum,
    bookedTo: state[AssignmentField.bookedTo]
      ? new Date(state[AssignmentField.bookedTo])
      : undefined,
    estimatedDuration: state[AssignmentField.estimatedDuration]
      ? Number(state[AssignmentField.estimatedDuration])
      : undefined,
    estimatedStartTime: state[AssignmentField.estimatedStartTime]
      ? new Date(state[AssignmentField.estimatedStartTime])
      : undefined,
  });

  const getNextStartedAssignmentTime = useCallback(async (): Promise<
    Date | undefined
  > => {
    // first, get all assignments for the user for the same day
    const assignmentsForDate =
      await getBookedAssignmentsForUserAndDate.mutateAsync({
        params: {
          userId: Number(state[AssignmentField.assignedTo]),
          date: new Date(state[AssignmentField.estimatedStartTime]),
        },
      });

    // next, find the next assignment that has started
    const nextStartedAssignment = assignmentsForDate
      .filter((a) => a.assignment.startTime)
      .sort(makeDateComparator((a) => a.assignment.startTime!))
      .find(
        (a) =>
          a.assignment.startTime &&
          a.assignment.startTime >
            new Date(state[AssignmentField.estimatedStartTime])
      );
    return nextStartedAssignment?.assignment.startTime;
  }, [getBookedAssignmentsForUserAndDate, state]);

  // // If the estimated duration changes to above 5h, automatically set the break time to 30 minutes
  // useEffect(() => {
  //   // update break time if estimated duration is above 5h
  //   if (
  //     Number(state[AssignmentField.estimatedDuration]) >=
  //     10 * hourInMilliseconds
  //   ) {
  //     if (
  //       state[AssignmentField.breakTime] === '' ||
  //       Number(state[AssignmentField.breakTime]) > 60
  //     ) {
  //       onChange(assignmentId, AssignmentField.breakTime, '60');
  //     }
  //   } else if (
  //     Number(state[AssignmentField.estimatedDuration]) >=
  //     5 * hourInMilliseconds
  //   ) {
  //     if (
  //       state[AssignmentField.breakTime] === '' ||
  //       Number(state[AssignmentField.breakTime]) > 30
  //     ) {
  //       onChange(assignmentId, AssignmentField.breakTime, '30');
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [assignmentId, state[AssignmentField.estimatedDuration]]);

  useEffect(() => {
    const { startTime } =
      calculateResultingStartAndEndTimeWithoutEstimatedStartTime();
    if (startTime !== null) {
      // hold off with automatically setting the estimated start time until we have better data
      // onChange(
      //   assignmentId,
      //   AssignmentField.estimatedStartTime,
      //   toInputDateTimeString(startTime)
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assignmentId,
    onChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    state[AssignmentField.estimatedDuration],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    state[AssignmentField.bookedTo],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    state[AssignmentField.assignmentTypeID],
    currentCaseType,
  ]);

  const isUsingRecommendedDuration =
    state[AssignmentField.estimatedDuration] ===
    String(suggestedEstimatedDuration?.average ?? 0);

  const isNew = assignmentId < 0;

  const input =
    (
      field: AssignmentField
    ): React.FormEventHandler<HTMLInputElement | HTMLSelectElement> =>
    (eve) =>
      onChange(assignmentId, field, eve.currentTarget.value);

  const mkInput = (
    name: AssignmentField,
    label: string,
    type?: React.HTMLInputTypeAttribute
  ): KeyListRow => ({
    key: label,
    value: (
      <Input onChange={input(name)} small type={type} value={state[name]} />
    ),
  });

  const mkAddressInput = (
    name: AssignmentField,
    address: AssignmentField,
    city: AssignmentField,
    zip: AssignmentField,
    county: AssignmentField,
    label: string,
    filter?: (c: CompanyModel) => boolean
  ): KeyListRow => {
    const adjustBreakTimeIfNecessary = (
      companyTransportPrice: CompanyTransportPriceModel | null | undefined
    ) => {
      if (
        companyTransportPrice &&
        companyTransportPrice.fixedTimeInMillseconds >= 5 * hourInMilliseconds
      ) {
        const numberOf5hBlocks = Math.floor(
          companyTransportPrice.fixedTimeInMillseconds /
            (5 * hourInMilliseconds)
        );

        const breakTime = numberOf5hBlocks * 30;
        onChange(assignmentId, AssignmentField.breakTime, breakTime.toString());
      } else {
        onChange(assignmentId, AssignmentField.breakTime, '');
      }
    };

    const companyFieldChanged = (fieldName: AssignmentField, value: string) => {
      onChange(assignmentId, fieldName, value);

      const companyTransportPrice = getFixedAssignmentTimeFromAddresses({
        ...state,
        [fieldName]: value,
      });

      onChange(
        assignmentId,
        AssignmentField.fixedAssignmentTimeInHours,
        companyTransportPrice
          ? (
              companyTransportPrice.fixedTimeInMillseconds / hourInMilliseconds
            ).toString()
          : ''
      );

      // if the fixed assignment exceeds 5h, set the break time to 30 minutes per 5h
      adjustBreakTimeIfNecessary(companyTransportPrice);
    };

    return {
      key: label,
      value: (
        <Row>
          <AddressInput
            address={state[address]}
            city={state[city]}
            companyOptionFilter={filter}
            countyId={state[county]}
            name={state[name]}
            onAddressChanged={(v) => companyFieldChanged(address, v)}
            onCityChanged={(v) => companyFieldChanged(city, v)}
            onCompanyPicked={(company, newCounty) => {
              onChange(assignmentId, address, company.address);
              onChange(assignmentId, name, company.name);
              onChange(assignmentId, city, company.city);
              onChange(assignmentId, county, newCounty?.id.toString() ?? '');
              onChange(assignmentId, zip, company.zip);
              clearEstimatedDuration();

              const companyTransportPrice = getFixedAssignmentTimeFromAddresses(
                {
                  ...state,
                  [address]: company.address,
                  [name]: company.name,
                  [city]: company.city,
                  [county]: newCounty?.id.toString() ?? '',
                  [zip]: company.zip,
                }
              );

              onChange(
                assignmentId,
                AssignmentField.fixedAssignmentTimeInHours,
                companyTransportPrice
                  ? (
                      companyTransportPrice.fixedTimeInMillseconds /
                      hourInMilliseconds
                    ).toString()
                  : ''
              );

              // if the fixed assignment exceeds 5h, set the break time to 30 minutes per 5h. Otherwise, set it to undefined
              adjustBreakTimeIfNecessary(companyTransportPrice);
            }}
            onCountyChanged={(v) =>
              // County field is not used to look up CompanyTransportPrices
              onChange(assignmentId, county, v?.id.toString() ?? '')
            }
            onNameChanged={(v) => companyFieldChanged(name, v)}
            onZipChanged={(v) => companyFieldChanged(zip, v)}
            zip={state[zip]}
          />
        </Row>
      ),
    };
  };

  const rows: KeyListRow[] = [
    mkInput(AssignmentField.bookedTo, 'Bokad till', 'datetime-local'),
    ...arraySpreadIf(
      currentAssignment.assignmentStatusID === AssignmentStatusEnum.Approved &&
        currentAssignment.assignmentDeviationID ===
          AssignmentDeviationEnum.None &&
        !!loggedInUser?.roles.includes(Roles.GoSupport),
      {
        key: 'Fälttestarutvärdering',
        value: (
          <Select
            onChange={input(AssignmentField.assignedToRating)}
            small
            value={state[AssignmentField.assignedToRating]}
          >
            {
              // undefined option
            }
            <option value={undefined}>Välj ett betyg</option>
            {
              // 1-5
              Array.from(Array(5).keys()).map((i) => (
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))
            }
          </Select>
        ),
      }
    ),
    ...arraySpreadIf(
      currentAssignment.assignmentStatusID === AssignmentStatusEnum.Approved &&
        currentAssignment.assignmentDeviationID ===
          AssignmentDeviationEnum.None &&
        !!loggedInUser?.roles.includes(Roles.GoSupport),
      {
        key: 'Fälttestarutvärdering anledning',
        value: (
          <ExtraInfoInput
            // disabled
            onChange={(eve) =>
              onChange(
                assignmentId,
                AssignmentField.assignedToRatingReason,
                eve.currentTarget.value
              )
            }
            value={state[AssignmentField.assignedToRatingReason]}
          />
        ),
      }
    ),
    {
      key: 'Typ',
      value: (
        <Select
          onChange={(eve) => {
            input(AssignmentField.assignmentTypeID)(eve);
            clearEstimatedDuration();
          }}
          small
          value={state[AssignmentField.assignmentTypeID]}
        >
          {assignmentTypeOptions.map((assignmentType) => (
            <option key={assignmentType} value={assignmentType}>
              {assignmentTypes[assignmentType]}
            </option>
          ))}
        </Select>
      ),
    },
    ...arraySpreadIf(multiplePurchasesList.length > 0, {
      key: 'Flerbilsinköp',
      value: (
        <>
          {multiplePurchasesList.map((ass) => (
            <TextButton
              key={ass.assignment.assignmentID}
              onClick={() =>
                window.open(
                  `/sok/${ass.case.caseID}`,
                  ass.case.caseID.toString() ?? '_blank'
                )
              }
              title={`Flerbilsinköp med ${ass.case.registrationNumber}`}
            >
              {ass.case.registrationNumber}{' '}
              {!!ass.assignment.estimatedDuration &&
                ass.assignment.assignedTo?.userID ===
                  Number(state[AssignmentField.assignedTo]) && (
                  <FontAwesomeIcon
                    title={`${ass.assignment.assignedTo?.name} grunduppdrag`}
                    icon={faClock}
                  />
                )}
              {ass.assignment.assignedTo?.userID ===
                Number(state[AssignmentField.assignedTo]) && (
                <FontAwesomeIcon
                  title={ass.assignment.assignedTo?.name}
                  icon={faUserAlt}
                />
              )}{' '}
              <FontAwesomeIcon icon={faExternalLink} />
            </TextButton>
          ))}
        </>
      ),
    }),
    {
      key: 'Status',
      value: (
        <Select
          onChange={input(AssignmentField.assignmentStatusID)}
          small
          value={state[AssignmentField.assignmentStatusID]}
        >
          {getSelectableStatusOptions(
            unmodifiedAssignment?.assignmentStatusID ??
              AssignmentStatusEnum.Created,
            currentCaseType
          ).map((status) => (
            <option
              disabled={statusOptionIsDisabled({
                currentAssignmentStatus: Number(status),
                events: caseEvents,
                currentAssignedToId: currentAssignment.assignedTo?.userID,
                currentAssignmentId: currentAssignment.assignmentID,
                drivingPolicyAssignmentInNeedOfHandling:
                  currentAssignment.drivingPolicyAssignmentInNeedOfHandling,
              })}
              key={status}
              value={status}
            >
              {assignmentStatuses[status]}
            </option>
          ))}
        </Select>
      ),
    },
    ...arraySpreadIf(currentCaseType !== CaseTypeEnum.Placeholder, {
      key: 'Tillåt privat mellanlandning',
      value: (
        <Checkbox
          checked={state[AssignmentField.allowPrivateStopOver] === 'true'}
          onChange={(eve) =>
            onChange(
              assignmentId,
              AssignmentField.allowPrivateStopOver,
              eve.currentTarget.checked ? 'true' : 'false'
            )
          }
        />
      ),
    }),
    ...arraySpreadIf(
      currentCaseType !== CaseTypeEnum.Placeholder &&
        state[AssignmentField.assignmentCancelTypeID] !== '',
      {
        key: 'Avbokningstyp',
        value: (
          <Select
            onChange={input(AssignmentField.assignmentCancelTypeID)}
            small
            value={state[AssignmentField.assignmentCancelTypeID]}
          >
            {getSelectableCancelTypeOptions(currentAssignment).map(
              ({ option, disabled }, index) => (
                <option
                  disabled={disabled}
                  key={`avbokningstyp-${option}-${index}`}
                  value={option}
                >
                  {option !== undefined ? cancelTypes[option] : 'Ingen vald'}
                </option>
              )
            )}
          </Select>
        ),
      }
    ),
    ...arraySpreadIf(
      caseTypeShowsCustomerUpdateMessagesToggle(currentCaseType),
      {
        key: 'Skicka SMS',
        value: (
          <Checkbox
            checked={
              currentCaseType !== CaseTypeEnum.Placeholder &&
              state[AssignmentField.sendCustomerUpdateMessages] === 'true'
            }
            onChange={(eve) =>
              onChange(
                assignmentId,
                AssignmentField.sendCustomerUpdateMessages,
                eve.currentTarget.checked ? 'true' : 'false'
              )
            }
          />
        ),
      }
    ),
    ...arraySpreadIf(
      currentCaseType !== CaseTypeEnum.Placeholder,

      {
        key: 'Auto-godkänn',
        value: (
          <Checkbox
            checked={state[AssignmentField.autoApprove] === 'true'}
            onChange={(eve) =>
              onChange(
                assignmentId,
                AssignmentField.autoApprove,
                eve.currentTarget.checked ? 'true' : 'false'
              )
            }
          />
        ),
      }
    ),

    mkAddressInput(
      AssignmentField.fromName,
      AssignmentField.fromAddress,
      AssignmentField.fromCity,
      AssignmentField.fromZip,
      AssignmentField.fromCounty,
      'Från',
      (c) => {
        if (
          currentAssignment.assignmentTypeID ===
          AssignmentTypeEnum.FurtherTransport
        ) {
          // further transports allow all addresses
          return true;
        }
        switch (currentCaseType) {
          case CaseTypeEnum.InternalDelivery:
            // only show logistics centers (i.e. Strängnäs) or Zäkra
            return (
              c.isLogisticsCenter === true || c.name.toLowerCase() === 'zäkra'
            );
          case CaseTypeEnum.InternalDeliverySales:
            // only show logistics center (i.e. Strängnäs)
            return (
              c.isLogisticsCenter === true ||
              c.name.toLowerCase().startsWith('zäkra hub')
            );
          case CaseTypeEnum.FacilityToFacility:
            // only show non-logistic isRiddermark addresses
            return (
              (!c.isLogisticsCenter && c.isRiddermark) ||
              c.name.toLowerCase() === 'zäkra hub järfälla'
            );
          case CaseTypeEnum.ExtraCostExternalWorkshopReturn:
            // only show external workshop addresses
            return (
              c.isExternalWorkshop === true || c.name.toLowerCase() === 'zäkra'
            );
          case CaseTypeEnum.ExtraCostExternalWorkshopOut:
            // show only logistics centers (i.e. Strängnäs)
            return (
              c.isLogisticsCenter === true || c.name.toLowerCase() === 'zäkra'
            );
          default:
            return true;
        }
      }
    ),
    mkAddressInput(
      AssignmentField.toName,
      AssignmentField.toAddress,
      AssignmentField.toCity,
      AssignmentField.toZip,
      AssignmentField.toCounty,
      'Till',
      (c) => {
        switch (currentCaseType) {
          case CaseTypeEnum.InternalDelivery:
          case CaseTypeEnum.InternalDeliverySales:
            // only riddermark addresses, internal overview companies, and Zäkra's address
            return (
              c.showInInternalDeliveryOverview === true ||
              c.isRiddermark === true ||
              c.isLogisticsCenter === true ||
              c.name.toLowerCase().startsWith('zäkra hub')
            );
          case CaseTypeEnum.FacilityToFacility:
            // only show non-logistic isRiddermark addresses
            return (
              (!c.isLogisticsCenter && c.isRiddermark) ||
              c.name.toLowerCase() === 'zäkra hub järfälla'
            );
          case CaseTypeEnum.ExtraCostExternalWorkshopOut:
            // only show external workshop addresses
            return (
              c.isExternalWorkshop === true || c.name.toLowerCase() === 'zäkra'
            );
          case CaseTypeEnum.ExtraCostExternalWorkshopReturn:
            // show only logistics centers (i.e. Strängnäs)
            return (
              c.isLogisticsCenter === true || c.name.toLowerCase() === 'zäkra'
            );
          default:
            return true;
        }
      }
    ),

    {
      key: 'Fälttestare',
      value: (
        <Centered>
          {assignmentId === -1 && state[AssignmentField.assignedTo] !== '' ? (
            <span>
              {usersById[Number(state[AssignmentField.assignedTo])]?.name}
            </span>
          ) : (
            <PrioritizedCaseAssignmentUserPicker
              actualEndTime={state[AssignmentField.endTime]}
              actualStartTime={state[AssignmentField.startTime]}
              assignmentId={assignmentId}
              assignmentType={Number(state[AssignmentField.assignmentTypeID])}
              bookedTo={state[AssignmentField.bookedTo]}
              caseModel={caseDataFormToCaseModel(undefined, caseState, [])}
              estimatedDuration={Math.floor(
                Number(state[AssignmentField.estimatedDuration] ?? 0)
              )}
              estimatedStartTime={
                state[AssignmentField.estimatedStartTime] ??
                resultingStartTime?.toString()
              }
              fromZipCode={state[AssignmentField.fromZip]}
              onUserPicked={async (user) => {
                onChange(
                  assignmentId,
                  AssignmentField.assignedTo,
                  user?.userID.toString() ?? ''
                );

                const currentStatus = Number(
                  state[AssignmentField.assignmentStatusID]
                );

                // Deselcting user changes status to Created
                if (
                  !user &&
                  (currentStatus === AssignmentStatusEnum.Planned ||
                    currentStatus === AssignmentStatusEnum.Assigned)
                ) {
                  onChange(
                    assignmentId,
                    AssignmentField.assignmentStatusID,
                    AssignmentStatusEnum.Created.toString()
                  );
                }

                // Selecting user changes status to Planned
                if (
                  user &&
                  (currentStatus === AssignmentStatusEnum.Created ||
                    currentStatus === AssignmentStatusEnum.Assigned)
                ) {
                  onChange(
                    assignmentId,
                    AssignmentField.assignmentStatusID,
                    AssignmentStatusEnum.Planned.toString()
                  );
                }
              }}
              selectedUserId={
                state[AssignmentField.assignedTo] === ''
                  ? null
                  : Number(state[AssignmentField.assignedTo])
              }
            />
          )}

          {state[AssignmentField.assignedTo] !== '' && (
            <PaddedSpan>
              <UserModalLink
                userId={Number(state[AssignmentField.assignedTo])}
              />{' '}
              {(drivingPolicyReportResult.data &&
                isViolatingDrivingPolicy(drivingPolicyReportResult.data)) ||
                (drivingPolicyReportResult.data?.warnings.includes(
                  AssigneeWarning.CannotStayAtHotels
                ) && (
                  <TooltipInfoWarning
                    warnings={drivingPolicyReportResult.data?.warnings}
                    showOnlyTooltip
                  />
                ))}
              {state[AssignmentField.assignedTo] && assignmentId >= 0 && (
                <WorkHoursSpan
                  drivinPolicyReport={drivingPolicyReportResult.data}
                />
              )}
            </PaddedSpan>
          )}
        </Centered>
      ),
    },
    {
      key: 'Estimerad uppdragstid',
      value: (
        <Horizontal>
          <div style={{ width: 220, display: 'flex', flexDirection: 'row' }}>
            <EstimatedDurationPicker
              assignment={currentAssignment}
              caseType={currentCaseType}
              // disable the estimated duration picker if the assignment has a fixed assignment time
              disabled={
                !!state[AssignmentField.fixedAssignmentTimeInHours] ||
                (currentAssignment.assignmentStatusID >=
                  AssignmentStatusEnum.Complete &&
                  !loggedInUser?.roles.includes(
                    /* Roles.GoSupport */ Roles.Admin
                  )) // temporary allow all admins to change start time (during summer vacation)
              }
              hideSelectAverageCheckbox
              onChangeEstimatedDuration={(value: number) => {
                const roundedValue = Math.floor(value);
                onChange(
                  assignmentId,
                  AssignmentField.estimatedDuration,
                  String(roundedValue)
                );
              }}
              onChangeSystemEstimatedDurationApprox={(value: number) => {
                onChange(
                  assignmentId,
                  AssignmentField.systemEstimatedDurationApprox,
                  value.toString()
                );
              }}
              onChangeSystemEstimatedDurationExact={(value: number) => {
                onChange(
                  assignmentId,
                  AssignmentField.systemEstimatedDurationExact,
                  value.toString()
                );
              }}
            />
          </div>
          {
            // show if no other assignment has an estimated duration and start time
            multiplePurchasesList.length > 0 &&
              state[
                AssignmentField
                  .hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime
              ] !== 'true' && (
                <PaddedSpan style={{ opacity: 0.4 }}>
                  OBS! Inkludera samtliga inköpsuppdrag i den beräknade
                  totaltiden om du planerar att samma FT ska göra alla inköp.
                </PaddedSpan>
              )
          }
        </Horizontal>
      ),
    },
    ...arraySpreadIf(
      currentCaseType !== CaseTypeEnum.Placeholder &&
        state[AssignmentField.assignmentCancelTypeID] ===
          AssignmentCancelTypeEnum.CanceledNotStartedSameDay.toString(),
      {
        key: 'Ska ha standby-tid',
        value: (
          <Column>
            <Centered>
              <Select
                small
                onChange={(eve) =>
                  onChange(
                    assignmentId,
                    AssignmentField.standByAssignmentIsEligible,
                    eve.currentTarget.value
                  )
                }
                value={state[AssignmentField.standByAssignmentIsEligible]}
              >
                <option value="">Välj</option>
                <option value="true">Ja</option>
                <option value="false">Nej</option>
              </Select>
              <PaddedSpan>
                OBS! Standby-tid räknas in i löneunderlaget.
              </PaddedSpan>
            </Centered>
          </Column>
        ),
      }
    ),
    ...arraySpreadIf(
      currentCaseType !== CaseTypeEnum.Placeholder &&
        state[AssignmentField.assignmentCancelTypeID] ===
          AssignmentCancelTypeEnum.CanceledNotStartedSameDay.toString() &&
        state[AssignmentField.standByAssignmentIsEligible] === 'true',
      {
        key: 'Standby-tid',
        value: (
          <Column>
            <Centered>
              <RemovableInput
                min={0}
                max={8}
                maxLength={4}
                addLabel="+ Lägg till"
                isRemoved={
                  state[AssignmentField.standByAssignmentTimeInHours] === ''
                }
                onAddClick={async () => {
                  // check if another assignment (same day) has started after this one
                  const estimatedStartTime = new Date(
                    state[AssignmentField.estimatedStartTime]
                  );
                  const estimatedDurationInHours =
                    Number(state[AssignmentField.estimatedDuration]) /
                    hourInMilliseconds;
                  const nextStartedAssignmentTime =
                    await getNextStartedAssignmentTime();
                  // if so, use the time between the estimated start time of this assignment and the actual start time of the next one (max the estimated duration of this assignment or 8 hours)
                  if (nextStartedAssignmentTime) {
                    const diffInHoursToNextAssignment =
                      (nextStartedAssignmentTime.getTime() -
                        estimatedStartTime.getTime()) /
                      hourInMilliseconds;

                    const usedTime = Math.min(
                      diffInHoursToNextAssignment,
                      estimatedDurationInHours,
                      maxStandByTimeInHours
                    );

                    onChange(
                      assignmentId,
                      AssignmentField.standByAssignmentTimeInHours,
                      usedTime.toFixed(2)
                    );
                  }
                  // otherwise, use the estimated duration of this assignment (max 8 hours)
                  else
                    onChange(
                      assignmentId,
                      AssignmentField.standByAssignmentTimeInHours,
                      Math.min(
                        estimatedDurationInHours,
                        maxStandByTimeInHours
                      ).toString()
                    );
                }}
                onBlur={autoFormatNumberInputOnBlur}
                onChange={(eve) => {
                  const newValue = eve.currentTarget.value || '0'; // Default to 0 if user clears input with backspace
                  if (Number(newValue) > maxStandByTimeInHours) {
                    onChange(
                      assignmentId,
                      AssignmentField.standByAssignmentTimeInHours,
                      maxStandByTimeInHours.toString()
                    );
                  } else {
                    // cap the value to 2 decimal places unless it's an integer
                    const value = Number(newValue ?? 0);
                    const formattedValue = Number.isInteger(value)
                      ? value.toString()
                      : value.toFixed(2);

                    onChange(
                      assignmentId,
                      AssignmentField.standByAssignmentTimeInHours,
                      formattedValue // Default to 0 if user clears input with backspace
                    );
                  }
                }}
                onKeyDown={(eve) => {
                  // Special for chrome when the decimal point is a comma: when typing a '.',
                  // onChange value is an empty string, so we'll simply ignore all '.' keystrokes in favour of comma (',').
                  const localeUsesComma = (1.1).toLocaleString()[1] === ',';
                  if (localeUsesComma && eve.key === '.') eve.preventDefault();
                }}
                onRemoveClick={() =>
                  onChange(
                    assignmentId,
                    AssignmentField.standByAssignmentTimeInHours,
                    ''
                  )
                }
                small
                type="number"
                value={state[AssignmentField.standByAssignmentTimeInHours]}
              />
              {state[AssignmentField.standByAssignmentTimeInHours] && (
                <PaddedSpan>
                  {getHoursAndMinutesFromMillisecondsString(
                    Number(
                      state[AssignmentField.standByAssignmentTimeInHours]
                    ) * millisecondsInHour
                  )}{' '}
                </PaddedSpan>
              )}
            </Centered>
          </Column>
        ),
      }
    ),
    ...arraySpreadIf(
      currentCaseType !== CaseTypeEnum.Placeholder &&
        state[AssignmentField.standByAssignmentIsEligible] === 'true',
      {
        key: 'Standby-tid anledning',
        value: (
          <ExtraInfoInput
            onChange={(eve) =>
              onChange(
                assignmentId,
                AssignmentField.standByAssignmentTimeReason,
                eve.currentTarget.value
              )
            }
            placeholder="Ange andledning till standby-tid (om nödvändigt)"
            value={state[AssignmentField.standByAssignmentTimeReason]}
          />
        ),
      }
    ),
    ...arraySpreadIf(
      assignmentId === -1 && // new assignment
        newAssignmentOverrides !== undefined &&
        newAssignmentOverrides?.firstPossibleStartTime !== undefined, // last possible end time is set
      {
        key: 'Tidigast möjliga starttid',
        value: (
          <Input
            disabled
            small
            type="datetime-local"
            value={toInputDateTimeString(
              newAssignmentOverrides?.firstPossibleStartTime ?? new Date()
            )}
          />
        ),
      }
    ),
    {
      key: 'Estimerad starttid',
      value: (
        <>
          <Input
            disabled={
              !!state[AssignmentField.startTime] &&
              !loggedInUser?.roles.includes(/* Roles.GoSupport */ Roles.Admin) // temporary allow all admins to change start time (during summer vacation)
            }
            max={state[AssignmentField.bookedTo]}
            min={
              state[AssignmentField.bookedTo] &&
              !Number.isNaN(state[AssignmentField.estimatedDuration])
                ? toInputDateTimeString(
                    removeMilliseconds(
                      new Date(state[AssignmentField.bookedTo]),
                      Number(state[AssignmentField.estimatedDuration])
                    )!
                  )
                : undefined
            }
            onChange={input(AssignmentField.estimatedStartTime)}
            small
            style={{
              opacity: state[AssignmentField.startTime] ? 0.4 : 1,
            }}
            type="datetime-local"
            value={state[AssignmentField.estimatedStartTime]}
          />
          {state[AssignmentField.estimatedStartTime] ? (
            !state[AssignmentField.startTime] ? (
              <PaddedSpan style={{ opacity: 0.4 }}>
                Beräknad sluttid:
                {resultingEndTime ? formatDateTime(resultingEndTime) : ''}
              </PaddedSpan>
            ) : null
          ) : (
            <PaddedSpan style={{ opacity: 0.4 }}>
              Ingen estimerad starttid
            </PaddedSpan>
          )}
        </>
      ),
    },

    ...arraySpreadIf(
      currentCaseType !== CaseTypeEnum.Placeholder,
      mkInput(AssignmentField.startTime, 'Faktisk starttid', 'datetime-local')
    ),
    ...arraySpreadIf(
      assignmentId === -1 && // new assignment
        newAssignmentOverrides !== undefined &&
        newAssignmentOverrides?.lastPossibleEndTime !== undefined, // last possible end time is set
      {
        key: 'Senast möjlig sluttid',
        value: (
          <Input
            disabled
            small
            type="datetime-local"
            value={toInputDateTimeString(
              newAssignmentOverrides?.lastPossibleEndTime ?? new Date()
            )}
          />
        ),
      }
    ),
    ...arraySpreadIf(currentCaseType !== CaseTypeEnum.Placeholder, {
      key: 'Faktisk sluttid',
      value: (
        <>
          <Input
            min={state[AssignmentField.startTime]}
            onChange={input(AssignmentField.endTime)}
            small
            type="datetime-local"
            value={state[AssignmentField.endTime]}
          />
          {state[AssignmentField.estimatedDuration] &&
            state[AssignmentField.startTime] &&
            !state[AssignmentField.endTime] && (
              <PaddedSpan style={{ opacity: 0.4 }}>
                Beräknad sluttid:{' '}
                {resultingEndTime ? formatDateTime(resultingEndTime) : ''}
                {/* <Input
                  disabled
                  small
                  type="datetime-local"
                  value={
                    resultingEndTime ? formatDateTime(resultingEndTime) : '–'
                  }
                /> */}
              </PaddedSpan>
            )}
        </>
      ),
    }),
    ...arraySpreadIf(
      currentCaseType !== CaseTypeEnum.Placeholder ||
        state[AssignmentField.fixedAssignmentTimeInHours] !== '',
      {
        key: 'Fast uppdragstid (h)',
        value: (
          <RemovableInput
            addLabel="+ Lägg till"
            isRemoved={state[AssignmentField.fixedAssignmentTimeInHours] === ''}
            onAddClick={() =>
              onChange(
                assignmentId,
                AssignmentField.fixedAssignmentTimeInHours,
                '0'
              )
            }
            onBlur={autoFormatNumberInputOnBlur}
            onChange={(eve) =>
              onChange(
                assignmentId,
                AssignmentField.fixedAssignmentTimeInHours,
                eve.currentTarget.value || '0' // Default to 0 if user clears input with backspace
              )
            }
            onKeyDown={(eve) => {
              // Special for chrome when the decimal point is a comma: when typing a '.',
              // onChange value is an empty string, so we'll simply ignore all '.' keystrokes in favour of comma (',').
              const localeUsesComma = (1.1).toLocaleString()[1] === ',';
              if (localeUsesComma && eve.key === '.') eve.preventDefault();
            }}
            onRemoveClick={() =>
              onChange(
                assignmentId,
                AssignmentField.fixedAssignmentTimeInHours,
                ''
              )
            }
            small
            type="number"
            value={state[AssignmentField.fixedAssignmentTimeInHours]}
          />
        ),
      }
    ),
    ...arraySpreadIf(
      currentCaseType !== CaseTypeEnum.Placeholder,
      mkInput(AssignmentField.breakTime, 'Matrast (min)', 'number')
    ),
    ...arraySpreadIf(currentCaseType !== CaseTypeEnum.Placeholder, {
      key: 'Information från Ride',
      keyAlign: 'top',
      value: (
        <ExtraInfoInput
          // disabled
          onChange={(eve) =>
            onChange(
              assignmentId,
              AssignmentField.extraInfo,
              eve.currentTarget.value
            )
          }
          value={state[AssignmentField.extraInfo]}
        />
      ),
    }),
    {
      key: 'Förväntade utgifter',
      value: (
        <MultiSelect
          onChange={(v) => {
            onChange(
              assignmentId,
              AssignmentField.expectedExpenses,
              Array.from(v).join(',')
            );
          }}
          options={expenseTypeOptions}
          small
          value={
            new Set(
              state[AssignmentField.expectedExpenses] === ''
                ? []
                : state[AssignmentField.expectedExpenses].split(',')
            )
          }
        />
      ),
    },
    ...arraySpreadIf(
      currentCaseType === CaseTypeEnum.Placeholder &&
        isSelfTransportationAssignment(currentAssignment.assignmentTypeID),
      {
        key: 'Fälttestaren bokar egen biljett',
        value: (
          <Checkbox
            checked={
              state[AssignmentField.assigneeShouldBookTheirOwnTicket] === 'true'
            }
            onChange={(eve) =>
              onChange(
                assignmentId,
                AssignmentField.assigneeShouldBookTheirOwnTicket,
                eve.currentTarget.checked ? 'true' : 'false'
              )
            }
          />
        ),
      }
    ),
    ...arraySpreadIf(
      currentCaseType === CaseTypeEnum.Placeholder &&
        isSelfTransportationAssignment(currentAssignment.assignmentTypeID),
      {
        key: 'Biljett-information',
        value: (
          <ExtraInfoInput
            onChange={(eve) =>
              onChange(
                assignmentId,
                AssignmentField.ticketInfo,
                eve.currentTarget.value
              )
            }
            value={state[AssignmentField.ticketInfo]}
          />
        ),
      }
    ),
    {
      key: 'Information till fälttestare',
      value: (
        <StandardizedComments
          assignmentId={assignmentId ?? -1}
          commentsOnAssignment={JSON.parse(
            state[AssignmentField.standardizedComments] ?? []
          )}
          onChange={(newComments) => {
            // first, update any linked expense type information
            const existingComments = JSON.parse(
              state[AssignmentField.standardizedComments] ?? []
            ) as StandardizedCommentDto[];
            const expectedExpenses = JSON.parse(
              `[${state[AssignmentField.expectedExpenses]}]`
            ) as ExpenseType[];

            const uniqueExpectedExpenses = getUpdatedExpectedExpensesList({
              existingComments,
              existingExpectedExpenses: expectedExpenses,
              newComments,
              globalStandardizedComments,
            });

            // update the expected expenses state
            onChange(
              assignmentId,
              AssignmentField.expectedExpenses,
              uniqueExpectedExpenses.join(',')
            );

            // then update the standardized comments state
            onChange(
              assignmentId,
              AssignmentField.standardizedComments,
              JSON.stringify(newComments ?? [])
            );
          }}
        />
      ),
    },
  ];

  return (
    <Suspense fallback="Laddar...">
      <Wrapper isNew={isNew}>
        <Row>
          <StyledKVList colonKey rows={rows} />

          <Actions>
            {cancelable && (
              <TextButton onClick={() => onCancel(assignmentId)}>
                Avbryt
              </TextButton>
            )}
          </Actions>
        </Row>

        {validation && <FormValidations validations={validation} />}
      </Wrapper>
    </Suspense>
  );
};

export default CaseAssignmentForm;
