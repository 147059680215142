import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useUsers from 'contexts/basicData/useUsers';
import { formatDate } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Routes from 'constants/Routes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { AssignmentDTO } from 'api/model';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';
import { Centered, getTableRowClassName } from '../table-helpers';
import { ITranslationLookups } from 'api';
import useTranslations from 'contexts/basicData/useTranslations';
import { useGoSupportNeedToSetIsEligibleForStandbyTime } from 'api/go-support/go-support';

const TABLE_NAME = 'need-to-set-is-eligible-for-standby';

const NeedToSetIsEligibleForStandbyWidget = () => {
  const [data, setData] = useState<AssignmentDTO[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();
  const translations = useTranslations();

  const getNeedToSetEligibleForStandby =
    useGoSupportNeedToSetIsEligibleForStandbyTime(
      {
        startDate: daterange[0] ?? undefined,
        endDate: daterange[1] ?? undefined,
        businessUnitIDs: selectedBusinessUnits,
      },
      {
        query: {
          refetchInterval: UPDATE_INTERVAL,
        },
      }
    );

  useEffect(() => {
    if (getNeedToSetEligibleForStandby.data) {
      setData(getNeedToSetEligibleForStandby.data);
    } else {
      setData([] as AssignmentDTO[]);
    }
  }, [getNeedToSetEligibleForStandby.data]);

  const filteredNeedStandby = useMemo(() => {
    let filtered = [...data];

    if (searchText.length > 0) {
      filtered = filtered.length
        ? filtered
            .filter((c) => {
              return (
                c.registrationNumber
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                (users[c.assignedToID]?.name ?? '')
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            })
            .sort((a, b) => b.bookedTo.getTime() - a.bookedTo.getTime()) // Latest first
        : [];
    }

    return filtered;
  }, [data, searchText, users]);

  const columnSettings: ColumnSetting<AssignmentDTO, ITranslationLookups>[] =
    useMemo(() => {
      const columns: ColumnSetting<AssignmentDTO, ITranslationLookups>[] = [
        {
          head: 'Nr',
          render: (e, t, f, i) => i + 1,
          width: 15,
        },
        {
          head: 'Reg.nr',
          render: (row) => `${row.registrationNumber}`,
          width: 60,
          sortFunction: (a, b) => {
            return a.registrationNumber.localeCompare(b.registrationNumber);
          },
        },
        {
          head: 'Status',
          render: (row) =>
            `${row.assignmentStatus ? translations.assignmentStatuses[row.assignmentStatus] : ''}`,
          width: 50,
          sortFunction: (a, b) => {
            return a.assignmentStatus - b.assignmentStatus;
          },
        },
        {
          head: 'Datum',
          render: (row) => `${formatDate(row.bookedTo)}`,
          width: 100,
          sortFunction: (a, b) =>
            (a.bookedTo?.getTime() ?? 0) - (b.bookedTo?.getTime() ?? 0),
        },
        {
          head: 'Fälttestare',
          render: (row) => {
            return (
              <span>
                {users[row.assignedToID] ? users[row.assignedToID].name : ''}
              </span>
            );
          },
          width: 100,
          sortFunction: (a, b) =>
            (users[a.assignedToID]?.name ?? '').localeCompare(
              users[b.assignedToID]?.name ?? ''
            ),
        },
        {
          head: ' ',
          render: (row) => (
            <Centered>
              <FontAwesomeIcon
                onClick={() => {
                  window.open(
                    `${Routes.search.index}/${row.caseID}`,
                    row.caseID.toString() ?? '_blank'
                  );
                }}
                icon={faArrowUpRightFromSquare}
                fontSize="18px"
                style={{ cursor: 'pointer' }}
              />
            </Centered>
          ),
          width: 20,
        },
      ];
      return columns;
    }, [translations.assignmentStatuses, users]);
  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.NeedToSetIsEligibleForStandby]}
      count={data.length}
      isLoading={getNeedToSetEligibleForStandby.isLoading}
      info="Behöver avgöras om uppdraget ska ha standby eller ej, redigeras i uppdraget"
    >
      <Table
        columnSettings={columnSettings}
        rows={filteredNeedStandby}
        rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.id)}
        useColumnWidthAsFlexAmount
        renderProps={translations}
      />
    </Widget>
  );
};

export default NeedToSetIsEligibleForStandbyWidget;
