import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AssignmentCancelTypeEnum,
  AssignmentDeviationEnum,
  AssignmentStatusEnum,
  AssignmentViewModel,
} from 'api';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Select from 'components/inputs/Select';
import Modal, { modalContentClass } from 'components/Modal';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { alphabeticCompare } from 'utils/sorting';
import useTranslations from 'contexts/basicData/useTranslations';
import * as yup from 'yup';
import { useFormWithSchema } from 'hooks/useFormWithSchema';
import { toInputDateTimeString } from 'utils/date-helpers';
import { CaseTypeEnum } from 'api/model';
import Checkbox from 'components/inputs/Checkbox';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    display: flex;
    flex-direction: column;
    gap: 10px;

    select,
    textarea,
    input {
      width: auto;
    }
    textarea {
      min-width: 300px;
      min-height: 2em;
    }
  }
`;

const Info = styled.div`
  max-width: 40vw;
  padding: 10px;
`;

interface CancelFormState {
  cancelType: AssignmentCancelTypeEnum;
  endTime?: Date;
  comment?: string;
}

const allCancelTypes: AssignmentCancelTypeEnum[] = Object.values(
  AssignmentCancelTypeEnum
).filter((v) => typeof v === 'number') as number[];

interface Props {
  assignmentViewModel: AssignmentViewModel;
  onOkClick(
    assignmentStatus: AssignmentStatusEnum,
    assignmendDeviation: AssignmentDeviationEnum,
    cancelType: AssignmentCancelTypeEnum,
    closeCase: boolean,
    clearFixedAssignmentTime: boolean,
    otherAssignmentIds: number[],
    endTime?: Date,
    comment?: string
  ): void;
  onCloseClick(): void;
}

const CancelCaseAssignmentModal: FC<Props> = ({
  assignmentViewModel,
  onCloseClick,
  onOkClick,
}) => {
  const otherAssignmentsWithinSameCase = useMemo(
    () =>
      assignmentViewModel.case.assignmentList.filter(
        (a) =>
          a.assignmentID !== assignmentViewModel.assignment.assignmentID &&
          a.assignmentDeviationID === AssignmentDeviationEnum.None
      ),
    [
      assignmentViewModel.case.assignmentList,
      assignmentViewModel.assignment.assignmentID,
    ]
  );
  const [selectedOtherAssignmentIds, setSelectedOtherAssignmentIds] = useState<
    number[]
  >(otherAssignmentsWithinSameCase.map((a) => a.assignmentID)); // default to all other assignments in the case

  const { cancelTypes } = useTranslations();
  const validationSchema = yup.object<CancelFormState>().shape({
    cancelType: yup.number().required(),
    endTime: yup.date().when('cancelType', (cancelType, schema) => {
      return [
        AssignmentCancelTypeEnum.CanceledArrived,
        AssignmentCancelTypeEnum.CanceledNotArrived,
      ].includes(Number(cancelType))
        ? schema.notRequired()
        : schema.required('Sluttid måste anges');
    }),
    comment: yup.string().when('cancelType', (cancelType, schema) => {
      return [AssignmentCancelTypeEnum.CanceledOther].includes(
        cancelType as unknown as AssignmentCancelTypeEnum
      )
        ? schema.required('Skriv anledning till avbokning i kommentarsrutan')
        : schema.notRequired();
    }),
  });

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormWithSchema<typeof validationSchema>(validationSchema, {
    defaultValues: {
      cancelType: -1,
      endTime: assignmentViewModel.assignment.endTime ?? new Date(),
      comment: '',
    },
  });

  const state = watch();

  useEffect(() => {
    switch (state.cancelType) {
      case AssignmentCancelTypeEnum.CanceledArrived:
      case AssignmentCancelTypeEnum.CanceledNotArrived:
        setValue('endTime', undefined); // don't set end time - assignee will do that from the app

        break;

      default:
        setValue('endTime', new Date());
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.cancelType]);

  /**
   * Returns true if the cancel option is enabled for the current assignment
   */
  const optionEnabled = useCallback(
    (cancelOption: AssignmentCancelTypeEnum) => {
      if (
        assignmentViewModel.assignment.assignmentStatusID ===
        AssignmentStatusEnum.Complete
      ) {
        return true;
      }
      switch (cancelOption) {
        case AssignmentCancelTypeEnum.CanceledDayBefore:
          // return true if assignment has not started yet, unless this is an intra-day purchase assignment not from or between stockholm, västra götaland, or skåne län
          if (assignmentViewModel.case.caseTypeID === CaseTypeEnum.Purchase) {
            // check the from address (is it within stockholm, gothenburg or malmö?)
            const fromCounty =
              assignmentViewModel.assignment.fromCounty?.areaName?.toLowerCase() ??
              '';
            const toCounty =
              assignmentViewModel.assignment.toCounty?.areaName?.toLowerCase() ??
              '';
            if (
              !(
                ['stockholm', 'västra götaland', 'skåne'].includes(
                  fromCounty
                ) &&
                ['stockholm', 'västra götaland', 'skåne'].includes(toCounty)
              )
            ) {
              // we are not in or between stockholm, västra götaland, or skåne - check if we are on an intra-day assignment
              if (
                assignmentViewModel.assignment.bookedTo.getDate() ===
                new Date().getDate()
              ) {
                // we are on an intra-day assignment
                return false;
              }
              // we are not on an intra-day assignment
              return true;
            }
          }
          return !assignmentViewModel.assignment.startTime;
        case AssignmentCancelTypeEnum.CanceledNotStartedSameDay:
          // return true if assignment has not yet started
          return !assignmentViewModel.assignment.startTime;
        case AssignmentCancelTypeEnum.CanceledNotArrived:
          // return true if assignment has started and the status is lower than "Arrived"
          return (
            !!assignmentViewModel.assignment.startTime &&
            assignmentViewModel.assignment.assignmentStatusID <
              AssignmentStatusEnum.Arrived
          );
        case AssignmentCancelTypeEnum.CanceledArrived:
          // return true if assignment has started and the status is higher than "Arrived" but lower than "Completed"
          return (
            !!assignmentViewModel.assignment.startTime &&
            assignmentViewModel.assignment.assignmentStatusID >=
              AssignmentStatusEnum.Arrived &&
            assignmentViewModel.assignment.assignmentStatusID <
              AssignmentStatusEnum.Complete
          );
        case AssignmentCancelTypeEnum.CanceledRebooked:
          // return true if the assignment has not started yet
          return !assignmentViewModel.assignment.startTime;
        case AssignmentCancelTypeEnum.CanceledOther:
          // always enabled
          return true;

        default:
          return true;
      }
    },
    [assignmentViewModel]
  );

  const handleOkClick = async () => {
    switch (state.cancelType) {
      case AssignmentCancelTypeEnum.CanceledArrived: // Bom anlänt
        onOkClick(
          assignmentViewModel.assignment.endTime
            ? AssignmentStatusEnum.Aborted
            : AssignmentStatusEnum.AbortedNotFinished, // set to AssignmentDeviationEnum.Aborted if we have an endtime, otherwise AssignmentDeviationEnum.AbortedNotFinished
          AssignmentDeviationEnum.Aborted,
          state.cancelType,
          false, // never auto-close bom-körningar
          true,
          selectedOtherAssignmentIds,
          assignmentViewModel.assignment.endTime, // don't change end time - assignee will do that from the app
          'Bomkörning'
        );
        break;
      case AssignmentCancelTypeEnum.CanceledOther: // Övrigt
        onOkClick(
          AssignmentStatusEnum.Canceled,
          AssignmentDeviationEnum.Canceled,
          state.cancelType,
          false,
          true,
          selectedOtherAssignmentIds,
          state.endTime,
          `Uppdrag avbokat - "${state.comment}"`
        );
        break;
      case AssignmentCancelTypeEnum.CanceledRebooked: // Ombokning
        onOkClick(
          AssignmentStatusEnum.Canceled,
          AssignmentDeviationEnum.Canceled,
          state.cancelType,
          false,
          true,
          selectedOtherAssignmentIds,
          state.endTime,
          'Uppdrag ombokat'
        );
        break;
      case AssignmentCancelTypeEnum.CanceledDayBefore: // Avbok dagen innan
      case AssignmentCancelTypeEnum.CanceledNotStartedSameDay: // Avok ej påbörjat (samma dag)
        onOkClick(
          AssignmentStatusEnum.Canceled,
          AssignmentDeviationEnum.Canceled,
          state.cancelType,
          false,
          false,
          selectedOtherAssignmentIds,
          state.endTime,
          'Uppdrag avbokat'
        );
        break;
      case AssignmentCancelTypeEnum.CanceledNotArrived: // Avbok ej anlänt
        onOkClick(
          assignmentViewModel.assignment.endTime
            ? AssignmentStatusEnum.Canceled
            : AssignmentStatusEnum.CanceledNotFinished, // set to AssignmentDeviationEnum.Canceled if we have an endtime, otherwise AssignmentDeviationEnum.CanceledNotFinished
          AssignmentDeviationEnum.Canceled,
          state.cancelType,
          false,
          true,
          selectedOtherAssignmentIds,
          assignmentViewModel.assignment.endTime, // don't set end time - assignee will do that from the app
          'Uppdrag avbokat'
        );
        break;

      default:
        break;
    }
  };

  return (
    <MyModal
      buttons={[
        {
          icon: <FontAwesomeIcon icon={faTrash} />,
          label: 'Avboka',
          onClick: handleOkClick,
          disabled:
            !!errors.cancelType ||
            !!errors.endTime ||
            !!errors.comment ||
            state.cancelType === -1,
        },
        {
          label: 'Avbryt',
          onClick: () => onCloseClick(),
        },
      ]}
      onClose={() => onCloseClick()}
      title="Avboka uppdrag"
    >
      <LabelWrap label="Typ av avbokning">
        <Select
          onChange={(ev) =>
            setValue('cancelType', Number(ev.currentTarget.value))
          }
          value={state.cancelType}
        >
          <option
            key="default"
            onChange={() => setValue('cancelType', -1)}
            value={-1}
          >
            Välj typ av avbokning
          </option>
          {allCancelTypes
            .sort((a, b) => alphabeticCompare(cancelTypes[a], cancelTypes[b]))
            .map((cancelType) => (
              <option
                disabled={!optionEnabled(cancelType)}
                key={cancelType}
                value={cancelType}
              >
                {cancelTypes[cancelType]}
              </option>
            ))}
        </Select>
      </LabelWrap>
      {state.cancelType !== undefined &&
        (state.cancelType === AssignmentCancelTypeEnum.CanceledDayBefore ||
          state.cancelType ===
            AssignmentCancelTypeEnum.CanceledNotStartedSameDay) && (
          <Info>
            OBS! Denna avbokningstyp är avsedd för uppdrag utan körtid. Start-
            och sluttid kommer automatiskt att sättas till vald sluttid nedan om
            uppdraget redan har en körtid på sig, annars blir båda tiderna Bokad
            till-tiden.
            {state.cancelType ===
              AssignmentCancelTypeEnum.CanceledNotStartedSameDay && (
              <p style={{ fontWeight: 'bold' }}>
                Om du vill ge lön för uppdraget behöver du sätta Standby-tid som
                berättigad i uppdraget.
              </p>
            )}
          </Info>
        )}
      {assignmentViewModel.assignment.startTime &&
        state.cancelType !== undefined &&
        ![
          -1,
          AssignmentCancelTypeEnum.CanceledArrived,
          AssignmentCancelTypeEnum.CanceledNotArrived,
        ].includes(state.cancelType) && (
          <LabelWrap label="Sluttid för uppdraget">
            <Input
              onChange={(e) =>
                setValue('endTime', new Date(e.currentTarget.value))
              }
              type="datetime-local"
              value={state.endTime ? toInputDateTimeString(state.endTime) : ''}
            />
          </LabelWrap>
        )}

      <Info>
        <LabelWrap label={<strong>Valt uppdrag</strong>}>
          {assignmentViewModel.assignment.fromAddress} -{' '}
          {assignmentViewModel.assignment.toAddress}
        </LabelWrap>
        {otherAssignmentsWithinSameCase.length > 0 && (
          <LabelWrap
            label={<strong>Avboka övriga uppdrag i samma ärende</strong>}
          >
            <div style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
              {otherAssignmentsWithinSameCase.map((a) => (
                <Checkbox
                  key={a.assignmentID}
                  checked={
                    selectedOtherAssignmentIds?.findIndex(
                      (id) => id === a.assignmentID
                    ) !== -1
                  }
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setSelectedOtherAssignmentIds([
                        ...(selectedOtherAssignmentIds ?? []),
                        a.assignmentID,
                      ]);
                    } else {
                      setSelectedOtherAssignmentIds(
                        selectedOtherAssignmentIds?.filter(
                          (id) => id !== a.assignmentID
                        )
                      );
                    }
                  }}
                >
                  {a.fromAddress} - {a.toAddress} (
                  {a.assignedTo?.name ?? 'Ej tilldelat'})
                </Checkbox>
              ))}
            </div>
          </LabelWrap>
        )}
      </Info>

      {state.cancelType === AssignmentCancelTypeEnum.CanceledOther && (
        <LabelWrap label="Kommentar">
          <AutoSizedTextArea
            onChange={(e) => setValue('comment', e.currentTarget.value)}
            value={state.comment}
          />
        </LabelWrap>
      )}
    </MyModal>
  );
};

export default CancelCaseAssignmentModal;
