import { WidgetMetadata } from 'components/Dashboard/types';

export enum GoSupportWidgetsEnum {
  Comments,
  FaultyCar,
  DeviationReports,
  BookedToEditedByFT,
  NotAcceptedTomorrowsAssignments,
  EstimatedStartTimePassed,
  EstimatedEndTimePassed,
  BookedToTimePassed,
  WorkGearReports,
  FlaggedForSpeeding,
  NotLoadedTyres,
  PlaceholderAssignments,
  IncompleteDayPlan,
  AssignmentRescheduledToAfterMidnight,
  NeedToSetIsEligibleForStandby,
}

export const UPDATE_INTERVAL = 15000; // 15sek

export const DefaultGoSupportWidgets: WidgetMetadata<GoSupportWidgetsEnum>[] = [
  {
    identifier: GoSupportWidgetsEnum.FaultyCar,
    shortTitle: 'Fel på bil',
    longTitle: 'Fel på bil',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.Comments,
    shortTitle: 'Kommentarer',
    longTitle: 'Uppdrag med olästa kommentarer',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.DeviationReports,
    shortTitle: 'Avvikelserapporter',
    longTitle: 'Avvikelserapporter',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.BookedToEditedByFT,
    shortTitle: 'Bokat till-tid ändrat',
    longTitle: 'Bokat till-tid justerat av fälttestare',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.NotAcceptedTomorrowsAssignments,
    shortTitle: 'Ej accepterade uppdrag imorgon',
    longTitle: 'Ej accepterade uppdrag imorgon - fylls på efter 21.00',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.EstimatedStartTimePassed,
    shortTitle: 'Estimerad starttid passerad',
    longTitle: 'Estimerad starttid passerad',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.EstimatedEndTimePassed,
    shortTitle: 'Estimerad sluttid passerad',
    longTitle: 'Estimerad sluttid passerad',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.BookedToTimePassed,
    shortTitle: 'Bokad till-tid passerad',
    longTitle: 'Bokad till-tid passerad',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.WorkGearReports,
    shortTitle: 'Rapporterad utrustning',
    longTitle: 'Rapporterad utrustning (saknad eller ej verifierad)',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.FlaggedForSpeeding,
    shortTitle: 'Fortkörning',
    longTitle: 'Flaggad för fortkörning',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.NotLoadedTyres,
    shortTitle: 'Ej lastade däck',
    longTitle: 'Ej lastade däck vid hemleverans',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.IncompleteDayPlan,
    shortTitle: 'Ofullständig dagsplan',
    longTitle: 'Ofullständig dagsplan',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.AssignmentRescheduledToAfterMidnight,
    shortTitle: 'Ombokat till efter midnatt',
    longTitle: 'Uppdrag ombokat till att sluta efter midnatt',
    active: true,
  },
  {
    identifier: GoSupportWidgetsEnum.NeedToSetIsEligibleForStandby,
    shortTitle: 'Sätt standby berättigande',
    longTitle: 'Sätt berättigande för standby tid',
    active: true,
  },
];

export const LongTitles: Record<GoSupportWidgetsEnum, string> =
  DefaultGoSupportWidgets.reduce(
    (titles, widget) => ({
      ...titles,
      [widget.identifier]: widget.longTitle,
    }),
    {} as Record<GoSupportWidgetsEnum, string>
  );
