import {
  faArrowRight,
  faArrowRotateRight,
  faCarSide,
  faPause,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddressInput from 'components/AddressInput';
import FormattedAddress from 'components/FormattedAddress';
import Input from 'components/inputs/Input';
import Slider from 'components/inputs/Slider';
import TextButton from 'components/inputs/TextButton';
import KeyValueList from 'components/KeyValueList';
import Modal, { modalContentClass } from 'components/Modal';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { IAddress } from 'utils/address';
import { openGoogleMapsDirections } from 'utils/address-helper';
import {
  formatDateTime,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import usePlannedStopOverForm, {
  isSecondAssignmentMainAssignment,
} from './usePlannedStopOverForm';
import EstimatedDurationPicker from '../CaseAssignments/EstimatedDurationPicker';
import { AssignmentModel, CaseTypeEnum } from 'api/model';
import toast from 'react-hot-toast';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
  }
`;

const SubHeader = styled.h3`
  margin: 0;
  padding: 0;
  margin-top: 20px;
  font-size: ${({ theme }) => theme.sizes.font.medium};
`;

const EditableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const NoEditsWrapper = styled(EditableWrapper)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background.secondary};
  `}
`;

const SliderIcon = styled(FontAwesomeIcon)`
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 50%;
  padding: 1px;
`;

const Warning = styled.span`
  color: ${({ theme }) => theme.colors.foreground.warning};
  font-size: 0.9em;
`;

const DurationWrapper = styled.div<{ warning?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  ${({ warning }) =>
    warning &&
    css`
      color: ${({ theme }) => theme.colors.foreground.error};
    `}
`;

interface Props {
  currentCaseType: CaseTypeEnum;
  originalAssignment: AssignmentModel;
  onAddressPicked(
    stopoverAddress: IAddress,

    firstAssignmentBookedTo: Date,
    firstAssignmentEstimatedDuration: number,
    firstAssignmentEstimatedStartTime: Date,

    secondAssignmentBookedTo: Date,
    secondAssignmentEstimatedDuration: number,
    secondAssignmentEstimatedStartTime: Date
  ): void;
  onCancel(): void;
}

const PlannedStopOverModal: FC<Props> = ({
  currentCaseType,
  originalAssignment: incomingOriginalAssignment,
  onAddressPicked,
  onCancel,
}) => {
  // const [
  //   originalAssignmentEstimatedDuration,
  //   // setOriginalAssignmentEstimatedDuration,
  // ] = useState<number | undefined>(
  //   incomingOriginalAssignment.estimatedDuration
  // );

  // const originalAssignment = useMemo(
  //   (): AssignmentModel => ({
  //     ...incomingOriginalAssignment,
  //     estimatedDuration: originalAssignmentEstimatedDuration,
  //   }),
  //   [incomingOriginalAssignment, originalAssignmentEstimatedDuration]
  // );
  const {
    stopOverAddress,
    setStopOverAddressValue,
    estimatedDurationOptions,
    originalAssignmentFormData,
    startsTooLateOrTooEarly,
    endsTooLateOrTooEarly,
    disableCreation,
    originalStartTime,
    originalEndTime,
    stopoverFormData,
    updatedFirstAssignmentEndTimeBasedOnNewDurations,
    updatedSecondAssignmentEndTimeBasedOnNewDurations,
    fixedAssignmentTimeFromStartToStopover,
    fixedAssignmentTimeFromStopoverToEnd,
    hasFixedAssignmentTime,

    handleOriginalAssignmentEstimatedStartTimeChange,
    handleOriginalAssignmentEstimatedDurationChange,
    handleFirstAssignmentEstimatedStartChange,
    handleFirstAssignmentEstimatedDurationChange,
    handleSecondAssignmentEstimatedDurationChange,
    handleSecondAssignmentEstimatedStartChange,
    adjustFirstEstimatedStartTimeToPreviousDay,
    adjustSecondEstimatedStartTimeToNextDay,
    handleOk,
  } = usePlannedStopOverForm({
    originalAssignment: incomingOriginalAssignment,
    onAddressPicked,
    caseTypeId: currentCaseType,
  });

  return (
    <MyModal
      buttons={[
        {
          icon: <FontAwesomeIcon icon={faPause} />,
          label: 'Skapa planerad mellanlandning',
          disabled: disableCreation,
          onClick: handleOk,
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      title="Skapa planerad mellanlandning"
    >
      <p>
        När du mellanlandar det här uppdraget så skapas ett nytt uppdrag{' '}
        <strong>
          {`${isSecondAssignmentMainAssignment(currentCaseType) ? 'före huvuduppdraget' : 'efter huvuduppdraget'}`}{' '}
        </strong>
        för vidaretransport till den ursprungliga destinationen.
      </p>
      <NoEditsWrapper>
        <KeyValueList
          title="Originaluppdrag"
          colonKey
          rows={[
            {
              key: 'Från',
              value: (
                <FormattedAddress
                  address={incomingOriginalAssignment.fromAddress}
                  city={incomingOriginalAssignment.fromCity}
                  county={incomingOriginalAssignment.fromCounty?.areaName}
                  name={incomingOriginalAssignment.fromName}
                  zipCode={incomingOriginalAssignment.fromZip}
                />
              ),
            },
            {
              key: 'Est. uppdragstid',
              value: (
                <DurationWrapper>
                  <DurationWrapper>
                    <Input
                      // error={endsTooLateOrTooEarly}
                      type="datetime-local"
                      value={formatDateTime(
                        originalAssignmentFormData.watch('estimatedStartTime')
                      )}
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        handleOriginalAssignmentEstimatedStartTimeChange(date);
                      }}
                    />
                  </DurationWrapper>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    <EstimatedDurationPicker
                      assignment={originalAssignmentFormData.watch()}
                      blurOnSelect
                      caseType={currentCaseType}
                      onChangeEstimatedDuration={(v) =>
                        handleOriginalAssignmentEstimatedDurationChange(v)
                      }
                      onChangeSystemEstimatedDurationExact={() => {
                        // do nothing
                      }}
                      onChangeSystemEstimatedDurationApprox={() => {
                        // do nothing
                      }}
                      placeholder="Välj tid"
                      width={90}
                      hideMapsIcon
                    />
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>{formatDateTime(originalEndTime)}</span>
                  <TextButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      openGoogleMapsDirections({
                        fromAddress: incomingOriginalAssignment.fromAddress,
                        fromCity: incomingOriginalAssignment.fromCity,
                        fromZip: incomingOriginalAssignment.fromZip,
                        toAddress: incomingOriginalAssignment.toAddress,
                        toCity: incomingOriginalAssignment.toCity,
                        toZip: incomingOriginalAssignment.toZip,
                      });
                    }}
                  >
                    <img
                      alt="Google maps icon"
                      height={15}
                      src="/images/googlemapsiconsmall.png"
                      width={15}
                    />
                  </TextButton>
                </DurationWrapper>
              ),
            },
            {
              key: 'Till',
              value: (
                <FormattedAddress
                  address={incomingOriginalAssignment.toAddress}
                  city={incomingOriginalAssignment.toCity}
                  county={incomingOriginalAssignment.toCounty?.areaName}
                  name={incomingOriginalAssignment.toName}
                  zipCode={incomingOriginalAssignment.toZip}
                />
              ),
            },
          ]}
        />
      </NoEditsWrapper>
      <EditableWrapper>
        <KeyValueList
          title="Planerad mellanlandning"
          colonKey
          rows={[
            {
              key: 'Start',
              value: (
                <FormattedAddress
                  address={incomingOriginalAssignment.fromAddress}
                  city={incomingOriginalAssignment.fromCity}
                  county={incomingOriginalAssignment.fromCounty?.areaName}
                  name={incomingOriginalAssignment.fromName}
                  zipCode={incomingOriginalAssignment.fromZip}
                />
              ),
            },
            {
              key: 'Est. uppdragstid 1',
              value: (
                <Slider
                  disabled={hasFixedAssignmentTime}
                  options={estimatedDurationOptions}
                  onChange={handleFirstAssignmentEstimatedDurationChange}
                  value={stopoverFormData.watch(
                    'firstAssignmentEstimatedDurationId'
                  )}
                  sliderIcon={<SliderIcon icon={faCarSide} />}
                />
              ),
            },
            {
              key: 'Uppdrag 1',
              value: (
                <DurationWrapper>
                  {isSecondAssignmentMainAssignment(currentCaseType) ? (
                    // allow editing the first assignment start time
                    <>
                      <Input
                        error={startsTooLateOrTooEarly}
                        type="datetime-local"
                        value={formatDateTime(
                          stopoverFormData.watch(
                            'firstAssignmentEstimatedStartTime'
                          )
                        )}
                        onChange={(e) => {
                          const date = new Date(e.target.value);
                          handleFirstAssignmentEstimatedStartChange(date);
                        }}
                      />
                      {startsTooLateOrTooEarly && (
                        <>
                          <Warning>
                            Mellanlandningen börjar/slutar utanför arbetspasset.
                          </Warning>
                          <TextButton
                            style={{
                              fontSize: '0.9em',
                              padding: 0,
                              gap: 5,
                              display: 'flex',
                            }}
                            onClick={adjustFirstEstimatedStartTimeToPreviousDay}
                          >
                            <span>Flytta till föregående dag</span>
                            <FontAwesomeIcon icon={faArrowRotateRight} />
                          </TextButton>
                        </>
                      )}
                    </>
                  ) : (
                    // don't allow editing the first assignment start time
                    <span>
                      {formatDateTime(
                        stopoverFormData.watch(
                          'firstAssignmentEstimatedStartTime'
                        )
                      )}
                    </span>
                  )}
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {fixedAssignmentTimeFromStartToStopover
                      ? `${getHoursAndMinutesFromMillisecondsString(fixedAssignmentTimeFromStartToStopover)} (fast tid)`
                      : estimatedDurationOptions[
                            stopoverFormData.watch(
                              'firstAssignmentEstimatedDurationId'
                            )
                          ]
                        ? estimatedDurationOptions[
                            stopoverFormData.watch(
                              'firstAssignmentEstimatedDurationId'
                            )
                          ].label
                        : '-'}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {formatDateTime(
                      updatedFirstAssignmentEndTimeBasedOnNewDurations
                    )}
                  </span>
                </DurationWrapper>
              ),
            },
            {
              key: 'Mellanlandning',
              value: (
                <AddressInput
                  address={stopOverAddress.address}
                  city={stopOverAddress.city}
                  countyId={stopOverAddress.county?.id ?? null}
                  name={stopOverAddress.name}
                  zip={stopOverAddress.zip}
                  onAddressChanged={(newAddress) => {
                    setStopOverAddressValue('address', newAddress);
                  }}
                  onCityChanged={(newCity) => {
                    setStopOverAddressValue('city', newCity);
                  }}
                  onCountyChanged={(newCounty) => {
                    setStopOverAddressValue('county', newCounty ?? undefined);
                  }}
                  onNameChanged={(newName) => {
                    setStopOverAddressValue('name', newName);
                  }}
                  onZipChanged={(newZip) => {
                    setStopOverAddressValue('zip', newZip);
                  }}
                  onCompanyPicked={(company, newCounty) => {
                    // verify that this is not the same as the original from or to address
                    if (
                      (company.address ===
                        incomingOriginalAssignment.fromAddress &&
                        company.city === incomingOriginalAssignment.fromCity &&
                        company.zip === incomingOriginalAssignment.fromZip) ||
                      (company.address ===
                        incomingOriginalAssignment.toAddress &&
                        company.city === incomingOriginalAssignment.toCity &&
                        company.zip === incomingOriginalAssignment.toZip)
                    ) {
                      toast.error(
                        'Mellanlandningsadressen kan inte vara samma som originaladressen.'
                      );
                      return;
                    }
                    // set the new address
                    setStopOverAddressValue('county', newCounty);
                    setStopOverAddressValue('city', company.city);
                    setStopOverAddressValue('name', company.name);
                    setStopOverAddressValue('address', company.address);
                    setStopOverAddressValue('zip', company.zip);
                  }}
                />
              ),
            },
            {
              key: 'Est. uppdragstid 2',
              value: (
                <Slider
                  disabled={hasFixedAssignmentTime}
                  options={estimatedDurationOptions}
                  onChange={handleSecondAssignmentEstimatedDurationChange}
                  value={stopoverFormData.watch(
                    'secondAssignmentEstimatedDurationId'
                  )}
                  reverseDisplay
                  sliderIcon={<SliderIcon icon={faCarSide} />}
                />
              ),
            },
            {
              key: 'Uppdrag 2',
              value: (
                <DurationWrapper warning={endsTooLateOrTooEarly}>
                  {isSecondAssignmentMainAssignment(currentCaseType) ? (
                    // don't allow editing the second assignment start time
                    <span>
                      {formatDateTime(
                        stopoverFormData.watch(
                          'secondAssignmentEstimatedStartTime'
                        )
                      )}
                    </span>
                  ) : (
                    // allow editing the second assignment start time
                    <>
                      <Input
                        error={endsTooLateOrTooEarly}
                        type="datetime-local"
                        value={formatDateTime(
                          stopoverFormData.watch(
                            'secondAssignmentEstimatedStartTime'
                          )
                        )}
                        onChange={(e) => {
                          const date = new Date(e.target.value);
                          handleSecondAssignmentEstimatedStartChange(date);
                        }}
                      />
                      {endsTooLateOrTooEarly && (
                        <>
                          <Warning>
                            Mellanlandningen börjar/slutar utanför arbetspasset.
                          </Warning>
                          <TextButton
                            style={{
                              fontSize: '0.9em',
                              padding: 0,
                              gap: 5,
                              display: 'flex',
                            }}
                            onClick={adjustSecondEstimatedStartTimeToNextDay}
                          >
                            <span>Flytta till nästa dag</span>
                            <FontAwesomeIcon icon={faArrowRotateRight} />
                          </TextButton>
                        </>
                      )}
                    </>
                  )}
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {fixedAssignmentTimeFromStopoverToEnd
                      ? `${getHoursAndMinutesFromMillisecondsString(fixedAssignmentTimeFromStopoverToEnd)} (fast tid)`
                      : estimatedDurationOptions[
                            stopoverFormData.watch(
                              'secondAssignmentEstimatedDurationId'
                            )
                          ]
                        ? estimatedDurationOptions[
                            stopoverFormData.watch(
                              'secondAssignmentEstimatedDurationId'
                            )
                          ].label
                        : '-'}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {formatDateTime(
                      updatedSecondAssignmentEndTimeBasedOnNewDurations
                    )}
                  </span>
                </DurationWrapper>
              ),
            },
            {
              key: 'Slut',
              value: (
                <FormattedAddress
                  address={incomingOriginalAssignment.toAddress}
                  city={incomingOriginalAssignment.toCity}
                  county={incomingOriginalAssignment.toCounty?.areaName}
                  name={incomingOriginalAssignment.toName}
                  zipCode={incomingOriginalAssignment.toZip}
                />
              ),
            },
          ]}
        />

        {/* <SubHeader>Välj mellanlandningsadress på kartan</SubHeader>
      <PickAddressMap
        fromAddress={{
          name: incomingOriginalAssignment.toName,
          address: incomingOriginalAssignment.toAddress,
          zip: incomingOriginalAssignment.toZip,
          city: incomingOriginalAssignment.toCity,
          county: incomingOriginalAssignment.toCounty,
        }}
        originalToAddress={{
          name,
          address,
          zip,
          city,
          county: county ?? undefined,
        }}
        onAddressPicked={(newToAddress) => {
          console.log(newToAddress);
        }}
        onCancel={() => {
          console.log('cancel');
        }}
      /> */}
      </EditableWrapper>

      <NoEditsWrapper>
        <SubHeader style={{ margin: 0 }}>
          Ny uppdelning för vidaretransport
        </SubHeader>
        <KeyValueList
          title={`Uppdrag 1 (${isSecondAssignmentMainAssignment(currentCaseType) ? 'nytt uppdrag' : 'originaluppdrag'})`}
          colonKey
          rows={[
            {
              key: 'Från',
              value: (
                <FormattedAddress
                  address={incomingOriginalAssignment.fromAddress}
                  city={incomingOriginalAssignment.fromCity}
                  county={incomingOriginalAssignment.fromCounty?.areaName}
                  name={incomingOriginalAssignment.fromName}
                  zipCode={incomingOriginalAssignment.fromZip}
                />
              ),
            },
            {
              key: 'Est. uppdragstid',
              value: (
                <DurationWrapper>
                  <span>{formatDateTime(originalStartTime)}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {
                      estimatedDurationOptions[
                        stopoverFormData.watch(
                          'firstAssignmentEstimatedDurationId'
                        )
                      ].label
                    }
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {formatDateTime(
                      updatedFirstAssignmentEndTimeBasedOnNewDurations
                    )}
                  </span>
                </DurationWrapper>
              ),
            },
            {
              key: 'Till',
              value: !stopOverAddress.address ? (
                <Warning>Ange mellanlandningsadress</Warning>
              ) : (
                <FormattedAddress
                  address={stopOverAddress.address}
                  city={stopOverAddress.city}
                  county={stopOverAddress.county?.areaName ?? undefined}
                  name={stopOverAddress.name}
                  zipCode={stopOverAddress.zip}
                />
              ),
            },
          ]}
        />

        <KeyValueList
          title={`Uppdrag 2 (${isSecondAssignmentMainAssignment(currentCaseType) ? 'originaluppdrag' : 'nytt uppdrag'})`}
          colonKey
          rows={[
            {
              key: 'Från',
              value: !stopOverAddress.address ? (
                <Warning>Ange mellanlandningsadress</Warning>
              ) : (
                <FormattedAddress
                  address={stopOverAddress.address}
                  city={stopOverAddress.city}
                  county={stopOverAddress.county?.areaName ?? undefined}
                  name={stopOverAddress.name}
                  zipCode={stopOverAddress.zip}
                />
              ),
            },
            {
              key: 'Est. uppdragstid',
              value: (
                <DurationWrapper>
                  <span>
                    {formatDateTime(
                      stopoverFormData.watch(
                        'secondAssignmentEstimatedStartTime'
                      )
                    )}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {
                      estimatedDurationOptions[
                        stopoverFormData.watch(
                          'secondAssignmentEstimatedDurationId'
                        )
                      ].label
                    }
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {formatDateTime(
                      updatedSecondAssignmentEndTimeBasedOnNewDurations
                    )}
                  </span>
                </DurationWrapper>
              ),
            },
            {
              key: 'Till',
              value: (
                <FormattedAddress
                  address={incomingOriginalAssignment.toAddress}
                  city={incomingOriginalAssignment.toCity}
                  county={incomingOriginalAssignment.toCounty?.areaName}
                  name={incomingOriginalAssignment.toName}
                  zipCode={incomingOriginalAssignment.toZip}
                />
              ),
            },
          ]}
        />
      </NoEditsWrapper>
    </MyModal>
  );
};

export default PlannedStopOverModal;
