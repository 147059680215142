import {
  AssignmentCancelTypeEnum,
  AssignmentModel,
  AssignmentStatusEnum,
  CaseEventType,
  CaseTypeEnum,
} from 'api';
import { ApprovalStatus, EventModel } from 'api/model';
import { UserJsonData } from '../EventsAndComments/eventComponents/types';

export const caseTypeShowsCustomerUpdateMessagesToggle = (
  caseType: CaseTypeEnum
) =>
  [
    CaseTypeEnum.Purchase,
    CaseTypeEnum.HomeDelivery,
    CaseTypeEnum.FacilityToFacility,
    CaseTypeEnum.RMOther,
  ].includes(caseType);

export const caseTypeDefaultsToSendsCustomerUpdateMessages = (
  caseType: CaseTypeEnum
) =>
  [
    CaseTypeEnum.Purchase,
    CaseTypeEnum.HomeDelivery,
    CaseTypeEnum.FacilityToFacility,
  ].includes(caseType);

const allStatusOptions: AssignmentStatusEnum[] = Object.values(
  AssignmentStatusEnum
).filter((s) => typeof s === 'number') as number[];

export const getSelectableStatusOptions = (
  currentStatus: AssignmentStatusEnum,
  currentCaseType: CaseTypeEnum
): AssignmentStatusEnum[] =>
  allStatusOptions
    .sort((a, b) => a - b)
    .filter(
      (opt) =>
        // Show current status in the list, so that it's visible that it's selected
        opt === currentStatus ||
        ![
          /**
           * Approving, Aborting, Cancelling and Discarding assignments is only allowed via an action
           * from the Case view, in order to guide the user through the necessary steps.
           * */
          AssignmentStatusEnum.Approved,
          AssignmentStatusEnum.AbortedNotFinished,
          AssignmentStatusEnum.Aborted,
          AssignmentStatusEnum.CanceledNotFinished,
          AssignmentStatusEnum.Canceled,
          AssignmentStatusEnum.Inspected,
        ].includes(opt) ||
        // show AssignmentStatusEnum.Inspected when caseType is CaseTypeEnum.HomeDelivery
        (opt === AssignmentStatusEnum.Inspected &&
          currentCaseType === CaseTypeEnum.HomeDelivery)
    );

export type StatusOptionIsDisabledProps = {
  currentAssignmentStatus: AssignmentStatusEnum;
  events: EventModel[];
  currentAssignmentId?: number;
  currentAssignedToId?: number;
  drivingPolicyAssignmentInNeedOfHandling?: boolean;
};
export const statusOptionIsDisabled = ({
  currentAssignmentStatus,
  events,
  currentAssignedToId,
  currentAssignmentId,
  drivingPolicyAssignmentInNeedOfHandling,
}: StatusOptionIsDisabledProps) => {
  // return true for all options above AssignmentStatus.Assigned if there are CaseEvent.RequestAssignFieldTesterOutsideDrivingPolicy that have not been approved

  let relevantEvents = events;

  if (currentAssignmentId && currentAssignedToId) {
    // filter out events that are not related to the current assignment and user
    relevantEvents = relevantEvents.filter((c) => {
      let cUser;
      if (
        c.caseEventType ===
          CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy &&
        c.data
      ) {
        try {
          const data = JSON.parse(c.data) as UserJsonData;
          cUser = data.UserId;
        } catch (error) {
          // console.log('failed to parse data');
        }
      }
      return (
        c.assignmentID === currentAssignmentId && cUser === currentAssignedToId
      );
    });
  }

  if (currentAssignmentStatus >= AssignmentStatusEnum.Assigned) {
    // first, check if there are any approved requests for this assignment
    const dpRequests = relevantEvents.filter(
      (r) =>
        r.caseEventType ===
        CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy
    );

    if (dpRequests.length > 0) {
      // there are requests for the assignment, and they are all approved
      const result = dpRequests.some(
        (r) => r.approvalStatus !== ApprovalStatus.APPROVED
      );

      return result;
    }
    // if no requests have come for the assignment, check the drivingPolicyAssignmentInNeedOfHandling flag
    return drivingPolicyAssignmentInNeedOfHandling === undefined
      ? false
      : drivingPolicyAssignmentInNeedOfHandling;
  }
  return false;
};

const allCancelTypeOptions: AssignmentCancelTypeEnum[] = Object.values(
  AssignmentCancelTypeEnum
).filter((s) => typeof s === 'number') as number[];

const cancelOptionIsDisabled = (
  cancelOption: AssignmentCancelTypeEnum,
  assignment: AssignmentModel
) => {
  switch (cancelOption) {
    case AssignmentCancelTypeEnum.CanceledDayBefore:
      // return true if assignment is already started
      return !!assignment.startTime;
    case AssignmentCancelTypeEnum.CanceledNotStartedSameDay:
      // return true if assignment is already started
      return !!assignment.startTime;
    case AssignmentCancelTypeEnum.CanceledNotArrived:
    case AssignmentCancelTypeEnum.CanceledArrived:
      return !assignment.startTime;
    default:
      return false;
  }
};

type CancelTypeOption = {
  option: AssignmentCancelTypeEnum | undefined;
  disabled: boolean;
};
export const getSelectableCancelTypeOptions = (
  assignment: AssignmentModel
): CancelTypeOption[] => {
  const options: CancelTypeOption[] = allCancelTypeOptions.map((opt) => {
    return {
      option: opt,
      disabled: cancelOptionIsDisabled(opt, assignment),
    };
  });
  // add an undefined option to the start of the list
  options.unshift({ option: undefined, disabled: false });
  return options;
};
