import { useQueryClient } from '@tanstack/react-query';
import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssignmentGetAutoApproveAssignmentsCount } from 'api/assignment/assignment';
import {
  getGlobalSettingsGetSettingsQueryKey,
  useGlobalSettingsGetSettings,
  useGlobalSettingsUpdateSetting,
} from 'api/global-settings/global-settings';
import { GlobalSettingEnum } from 'api/model';
import KeyValueList from 'components/KeyValueList';
import Checkbox from 'components/inputs/Checkbox';
import Input from 'components/inputs/Input';
import useTranslations from 'contexts/basicData/useTranslations';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import TextButton from 'components/inputs/TextButton';
// import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import { showException } from 'utils/exception-helper';
import MultiSelect from 'components/inputs/MultiSelect';
import useUsers from 'contexts/basicData/useUsers';
import SupplierSettings from './SupplierSettings';

const PageWrapper = styled.div`
  padding: 20px;
  display: flex;
  gap: 40px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`;

const Horizontal = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Info = styled.div`
  font-size: 12px;
  color: #666;
`;

const GlobalSettingsPage = () => {
  const users = useUsers();
  const userOptions = useMemo(() => {
    return Object.values(users ?? {})
      .filter((u) => u.isActive)
      .map((user) => ({
        label: user.name,
        value: user.userID,
      }));
  }, [users]);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const { globalSettings: globalSettingsTranslations } = useTranslations();
  const queryClient = useQueryClient();
  const globalSettingsResponse = useGlobalSettingsGetSettings({
    query: {
      refetchInterval: 15000,
    },
  });
  const updateGlobalSettingsCall = useGlobalSettingsUpdateSetting();
  const updateglobalSettingsAsync = async (data: {
    key: GlobalSettingEnum;
    value: string;
  }) => {
    try {
      await updateGlobalSettingsCall.mutateAsync({ data });
    } catch (error) {
      showException(error);
    }
  };
  const autoApprovableAssignmentscount =
    useAssignmentGetAutoApproveAssignmentsCount();
  const { watch, reset, setValue } = useForm<{ [key: string]: string }>();

  useEffect(() => {
    reset(globalSettingsResponse.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSettingsResponse.data]);

  const refetchSettings = async () => {
    await queryClient.invalidateQueries([
      `${getGlobalSettingsGetSettingsQueryKey()}`,
    ]);
    globalSettingsResponse.refetch();
  };

  const handleInputChangeDebounced = async (
    inputSetting: GlobalSettingEnum,
    value: string
  ) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      await updateGlobalSettingsCall.mutateAsync({
        data: {
          key: inputSetting,
          value: value ?? 0,
        },
      });
      refetchSettings();
    }, 300);
  };

  return (
    <PageWrapper>
      <Wrapper>
        <h1>
          Inställningar{' '}
          {globalSettingsResponse.isLoading ||
            (updateGlobalSettingsCall.isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin />
            ))}
        </h1>
        <KeyValueList
          colonKey
          keyWidth="90px"
          rows={[
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.AutoApprovalEnabled
                ] ?? '',
              value: (
                <Checkbox
                  checked={
                    (watch(
                      String(GlobalSettingEnum.AutoApprovalEnabled)
                    ) as unknown as string) === 'true'
                  }
                  disabled={globalSettingsResponse.isFetching}
                  onChange={async (eve) => {
                    await updateglobalSettingsAsync({
                      key: GlobalSettingEnum.AutoApprovalEnabled,
                      value: eve.currentTarget.checked.toString(),
                    });
                    refetchSettings();
                  }}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.ConsecutiveHoursOfRestLast24Hours
                ] ?? '',
              value: (
                <Input
                  max={23}
                  min={0}
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      await updateglobalSettingsAsync({
                        key: GlobalSettingEnum.ConsecutiveHoursOfRestLast24Hours,
                        value: eve.currentTarget.value ?? 0,
                      });
                      refetchSettings();
                    }
                  }}
                  type="number"
                  value={watch(
                    String(GlobalSettingEnum.ConsecutiveHoursOfRestLast24Hours)
                  )}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.ConsecutiveHoursOfRestLast7Days
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      await updateglobalSettingsAsync({
                        key: GlobalSettingEnum.ConsecutiveHoursOfRestLast7Days,
                        value: eve.currentTarget.value ?? 0,
                      });
                      refetchSettings();
                    }
                  }}
                  type="number"
                  value={watch(
                    String(GlobalSettingEnum.ConsecutiveHoursOfRestLast7Days)
                  )}
                />
              ),
            },

            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.MaxDrivingHoursLast7DaysAveragePer4Weeks
                ] ?? '',
              value: (
                <Horizontal>
                  <Input
                    onChange={async (eve) => {
                      if (eve.currentTarget.value.length > 0) {
                        await updateglobalSettingsAsync({
                          key: GlobalSettingEnum.MaxDrivingHoursLast7DaysAveragePer4Weeks,
                          value: eve.currentTarget.value ?? 0,
                        });
                        refetchSettings();
                      }
                    }}
                    type="number"
                    value={watch(
                      String(
                        GlobalSettingEnum.MaxDrivingHoursLast7DaysAveragePer4Weeks
                      )
                    )}
                  />
                  <Info>
                    {`${
                      Number(
                        watch(
                          String(
                            GlobalSettingEnum.MaxDrivingHoursLast7DaysAveragePer4Weeks
                          )
                        )
                      ) * 4
                    } timmar per 4 veckor`}
                  </Info>
                </Horizontal>
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.MarginBetweenAssignmentsInMinutesWhenBooking
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      await updateglobalSettingsAsync({
                        key: GlobalSettingEnum.MarginBetweenAssignmentsInMinutesWhenBooking,
                        value: eve.currentTarget.value ?? 0,
                      });
                      refetchSettings();
                    }
                  }}
                  type="number"
                  value={watch(
                    String(
                      GlobalSettingEnum.MarginBetweenAssignmentsInMinutesWhenBooking
                    )
                  )}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.DaysAheadToSpecifyFTAvailability
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      await updateglobalSettingsAsync({
                        key: GlobalSettingEnum.DaysAheadToSpecifyFTAvailability,
                        value: eve.currentTarget.value ?? 7,
                      });
                      refetchSettings();
                    }
                  }}
                  type="number"
                  value={watch(
                    String(GlobalSettingEnum.DaysAheadToSpecifyFTAvailability)
                  )}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum
                    .MinDurationBetweenAssignmentArrivedAndTestComplete
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      await updateglobalSettingsAsync({
                        key: GlobalSettingEnum.MinDurationBetweenAssignmentArrivedAndTestComplete,
                        value: eve.currentTarget.value ?? 0,
                      });
                      refetchSettings();
                    }
                  }}
                  type="number"
                  value={watch(
                    String(
                      GlobalSettingEnum.MinDurationBetweenAssignmentArrivedAndTestComplete
                    )
                  )}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.ZakraCustomerSurveyExpiryHours
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      await updateglobalSettingsAsync({
                        key: GlobalSettingEnum.ZakraCustomerSurveyExpiryHours,
                        value: eve.currentTarget.value ?? 0,
                      });
                      refetchSettings();
                    }
                  }}
                  type="number"
                  value={watch(
                    String(GlobalSettingEnum.ZakraCustomerSurveyExpiryHours)
                  )}
                  defaultValue={48}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.MaxDistanceFromGoalLocationInKms
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      await updateglobalSettingsAsync({
                        key: GlobalSettingEnum.MaxDistanceFromGoalLocationInKms,
                        value: eve.currentTarget.value ?? 0,
                      });
                      refetchSettings();
                    }
                  }}
                  type="number"
                  value={watch(
                    String(GlobalSettingEnum.MaxDistanceFromGoalLocationInKms)
                  )}
                  defaultValue={1}
                />
              ),
            },
            {
              key: `Antal uppdrag som kan auto-godkännas`,
              value: <span>{autoApprovableAssignmentscount.data ?? 7}</span>,
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.EstimatedStartTimePassedThreshold
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      // Update the value in the form state for more responsive UI
                      setValue(
                        String(
                          GlobalSettingEnum.EstimatedStartTimePassedThreshold
                        ),
                        eve.target.value
                      );
                      // Update the value in the backend after a debounce to avoid row issues due to too many requests on fast typing
                      handleInputChangeDebounced(
                        GlobalSettingEnum.EstimatedStartTimePassedThreshold,
                        eve.currentTarget.value
                      );
                    }
                  }}
                  type="number"
                  min={0}
                  value={watch(
                    String(GlobalSettingEnum.EstimatedStartTimePassedThreshold)
                  )}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.EstimatedEndTimePassedThreshold
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      // Update the value in the form state for more responsive UI
                      setValue(
                        String(
                          GlobalSettingEnum.EstimatedEndTimePassedThreshold
                        ),
                        eve.target.value
                      );
                      // Update the value in the backend after a debounce to avoid row issues due to too many requests on fast typing
                      handleInputChangeDebounced(
                        GlobalSettingEnum.EstimatedEndTimePassedThreshold,
                        eve.currentTarget.value
                      );
                    }
                  }}
                  type="number"
                  min={0}
                  value={watch(
                    String(GlobalSettingEnum.EstimatedEndTimePassedThreshold)
                  )}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.BookedToTimePassedThreshold
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      // Update the value in the form state for more responsive UI
                      setValue(
                        String(GlobalSettingEnum.BookedToTimePassedThreshold),
                        eve.target.value
                      );
                      // Update the value in the backend after a debounce to avoid row issues due to too many requests on fast typing
                      handleInputChangeDebounced(
                        GlobalSettingEnum.BookedToTimePassedThreshold,
                        eve.currentTarget.value
                      );
                    }
                  }}
                  type="number"
                  min={0}
                  value={watch(
                    String(GlobalSettingEnum.BookedToTimePassedThreshold)
                  )}
                />
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.UsersWithAccessToPlaceholderAssignments
                ] ?? 'Användare med tillgång att boka utfyllnadsuppdrag',
              value: (
                <Horizontal>
                  <MultiSelect
                    onChange={(v) => {
                      setValue(
                        String(
                          GlobalSettingEnum.UsersWithAccessToPlaceholderAssignments
                        ),
                        Array.from(v)
                          .filter((x) => !Number.isNaN(x))
                          .join(',')
                      );
                    }}
                    options={userOptions}
                    value={
                      new Set(
                        watch(
                          String(
                            GlobalSettingEnum.UsersWithAccessToPlaceholderAssignments
                          )
                        )
                          ?.split(',')
                          ?.map((v) => parseInt(v, 10)) ?? []
                      )
                    }
                  />
                  {
                    // if the value is different from the original value, show a save button
                    watch(
                      String(
                        GlobalSettingEnum.UsersWithAccessToPlaceholderAssignments
                      )
                    ) !==
                      globalSettingsResponse.data?.[
                        String(
                          GlobalSettingEnum.UsersWithAccessToPlaceholderAssignments
                        )
                      ] && (
                      <TextButton
                        onClick={async () => {
                          await updateglobalSettingsAsync({
                            key: GlobalSettingEnum.UsersWithAccessToPlaceholderAssignments,
                            value: watch(
                              String(
                                GlobalSettingEnum.UsersWithAccessToPlaceholderAssignments
                              )
                            ),
                          });
                          refetchSettings();
                        }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </TextButton>
                    )
                  }
                </Horizontal>
              ),
            },
            {
              key:
                globalSettingsTranslations[
                  GlobalSettingEnum.MaxWorkingHoursPerDayForStandby
                ] ?? '',
              value: (
                <Input
                  onChange={async (eve) => {
                    if (eve.currentTarget.value.length > 0) {
                      setValue(
                        String(
                          GlobalSettingEnum.MaxWorkingHoursPerDayForStandby
                        ),
                        eve.currentTarget.value
                      );
                      handleInputChangeDebounced(
                        GlobalSettingEnum.MaxWorkingHoursPerDayForStandby,
                        eve.currentTarget.value
                      );
                    }
                  }}
                  type="number"
                  min={0}
                  value={watch(
                    String(GlobalSettingEnum.MaxWorkingHoursPerDayForStandby)
                  )}
                />
              ),
            },
          ]}
        />
      </Wrapper>
      <SupplierSettings />
    </PageWrapper>
  );
};

export default GlobalSettingsPage;
